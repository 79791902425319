import React, { useState, useRef, useEffect } from "react";
import "./homeStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { Tooltip as ReactToolTip } from "react-tooltip";
import Navbar from "../../components/navbar/Navbar";
import { useCookies } from "react-cookie";
import { eventFrequencyCall } from "../../utils/Util";
import {
  CONVERT,
  DOCUMENTATION,
  EXPLAIN,
  HOME,
  SAVED_QUERIES,
  VIEW,
  CLICK,
} from "../../data/constants";

export default function Home() {
  const convertYourQueryRef = useRef(null);
  const saveAQueryRef = useRef(null);
  const explainAQueryRef = useRef(null);
  const documentationRef = useRef(null);
  const [userName, setUserName] = useState(null);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [cookies, setCookie] = useCookies([
    "isLoggedIn",
    "username",
    "googleId",
  ]);

  useEffect(() => {
    const mobileMq = window.matchMedia(
      "(max-width: 767px) and (orientation: portrait)"
    );
    setIsMobileScreen(mobileMq.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobileScreen(event.matches);
    };

    mobileMq.addListener(handleMediaQueryChange);

    return () => {
      mobileMq.removeListener(handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    eventFrequencyCall(cookies.googleId, HOME, VIEW, "");
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "c" || event.key === "C") {
        convertYourQueryRef.current.click();
      } else if (
        (event.key === "s" || event.key === "S") &&
        cookies.isLoggedIn === true &&
        saveAQueryRef.current !== null
      ) {
        saveAQueryRef.current.click();
      } else if (event.key === "e" || event.key === "E") {
        explainAQueryRef.current.click();
      } else if (event.key === "d" || event.key === "D") {
        documentationRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleConvert = () => {
    eventFrequencyCall(cookies.googleId, HOME, CLICK, CONVERT);
  };

  const handleSavedQueries = () => {
    eventFrequencyCall(cookies.googleId, HOME, CLICK, SAVED_QUERIES);
  };

  const handleExplain = () => {
    eventFrequencyCall(cookies.googleId, HOME, CLICK, EXPLAIN);
  };

  const handleDocumentation = () => {
    eventFrequencyCall(cookies.googleId, HOME, CLICK, DOCUMENTATION);
  };

  return (
    <div className="home-bg-color home-main-container">
      <Navbar></Navbar>
      <div className="home-content">
        {!isMobileScreen && cookies.isLoggedIn ? (
          <label className="home-heading gray-curved-underline-heading">
            Hello {cookies.username}!
          </label>
        ) : (
          <label className="home-heading gray-curved-underline-heading">
            Hello!
          </label>
        )}

        <a
          ref={convertYourQueryRef}
          href="/convert"
          className="btn btn-light home-btn"
          id="convertYourQueryButton"
          onClick={handleConvert}
        >
          <span>Convert Your Query</span>
          <FontAwesomeIcon icon={faArrowRightLong} />
        </a>
        {cookies.isLoggedIn ? (
          <a
            ref={saveAQueryRef}
            href="/saved-queries"
            className="btn btn-light home-btn"
            onClick={handleSavedQueries}
          >
            <span>Save a Query</span>
            <FontAwesomeIcon icon={faArrowRightLong} />
          </a>
        ) : (
          <button
            className="btn btn-light home-btn disabledButton"
            id="saveAQueryButton"
          >
            <span>Save a Query</span>
            <FontAwesomeIcon icon={faArrowRightLong} />
          </button>
        )}
        <a
          ref={explainAQueryRef}
          href="/explain-query"
          className="btn btn-light home-btn  "
          onClick={handleExplain}
        >
          <span>Explain Query</span>
          <FontAwesomeIcon icon={faArrowRightLong} />
        </a>
        <a
          ref={documentationRef}
          href="/documentation"
          className="btn btn-light home-btn "
          onClick={handleDocumentation}
        >
          <span>Documentation</span>
          <FontAwesomeIcon icon={faArrowRightLong} />
        </a>
      </div>
      {!cookies.isLoggedIn ? (
        <ReactToolTip
          id="homeTooltip"
          anchorId="saveAQueryButton"
          place="top"
          content={
            isMobileScreen
              ? "This option is only available in Desktop"
              : "This option is available once connected with Google"
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
}
