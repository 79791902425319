import React, { useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';
import "./documentationStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { NavbarHomeBtn } from "../../components/navbar/Navbar";
import { SELECT_STATEMENT, FIND_DOCUMENTS, DOCUMENTATION, VIEW, CLICK, SQL, MONGODB } from "../../data/constants";
import { useCookies } from "react-cookie";
import { eventFrequencyCall } from "../../utils/Util";
import routeData from "../../utils/RouteData";

export default function Documentation() {
  const sqlRef = useRef(null);
  const mongoRef = useRef(null);
  const { title, metaDescription } = routeData['/documentation'];
  const [cookies, setCookie, removeCookie] = useCookies([
    "googleId",
  ]);

  useEffect(() => {
    eventFrequencyCall(cookies.googleId,DOCUMENTATION,VIEW,'');
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "s" || event.key === "S") {
        sqlRef.current.click();
      } else if (event.key === "m" || event.key === "M") {
        mongoRef.current.click();
      } else if (
        (event.key === "Backspace" && event.metaKey) ||
        (event.key === "Backspace" && event.ctrlKey)
      ) {
        const currentUrl = new URL(window.location.href);
        const segments = currentUrl.pathname
          .split("/")
          .filter((segment) => segment.trim() !== "");
        if (segments.length > 1) {
          segments.pop();
          currentUrl.pathname = "/" + segments.join("/");
        } else {
          currentUrl.pathname = "/";
        }

        window.location.href = currentUrl.toString();
      }
      console.log(window.innerWidth);
      console.log(window.innerHeight);
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSQL = () => {
    eventFrequencyCall(cookies.googleId,DOCUMENTATION,CLICK,SQL);
  };

  const handleMONGODB = () => {
    eventFrequencyCall(cookies.googleId,DOCUMENTATION,CLICK,MONGODB);
  };

  return (
    <React.Fragment>
       <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
    <div className="documentation-bg-color documentation-main-container">
          <NavbarHomeBtn
          source={DOCUMENTATION}></NavbarHomeBtn>

          <div className="documentation-content">
            <label className="documentation-heading">
              Select the language you want to learn!
            </label>
            <a
              ref={sqlRef}
              href={`/documentation/sql/${SELECT_STATEMENT}`}
              className="btn btn-light documentation-btn"
              onClick={handleSQL}
            >
              <span>SQL</span>
              <FontAwesomeIcon icon={faArrowRightLong} />
            </a>
            <a
              ref={mongoRef}
              href={`/documentation/mongo/${FIND_DOCUMENTS}`}
              className="btn btn-light documentation-btn "
              onClick={handleMONGODB}
            >
              <span>MongoDB</span>
              <FontAwesomeIcon icon={faArrowRightLong} />
            </a>
          </div>
        </div>
    </React.Fragment>
   
  );
}
