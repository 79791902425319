const routeData = {
  "/": {
    title:"Query Convert",
    metaDescription:"QueryConvert - Your one-stop solution for effortless query management. Convert SQL to MongoDB, save, and explain database queries in SQL, MongoDB, and more. Access comprehensive SQL and MongoDB documentation with examples. Choose from flexible light and dark themes for a comfortable browsing experience."
  },
  "/convert": {
    title: "Convert SQL to MongoDB Query",
    metaDescription:
      "Convert SQL queries to MongoDB online with our query conversion too`l.",
  },
  "/saved-queries": {
    title: "Save your Queries and organize yourself",
    metaDescription:
      "Save your queries and organize them into multiple folders for easy access.",
  },
  "/explain-query": {
    title: "Explain Query",
    metaDescription:
      "Understand any database query in a clear and concise way with our explanation tool.",
  },
  "/documentation": {
    title: "SQL & Mongo Documentation",
    metaDescription:
      "Pick a database language and start learning with our comprehensive documentation.",
  },

  // ----------------------------  SQL ----------------------------------
  "/documentation/sql/": {
    title: "SQL Documentation - Learn SQL from Basics to Advanced",
    metaDescription:
      "Explore our SQL documentation to master the SQL language from basics to advanced topics.",
  },
  "/documentation/sql/select-statement": {
    title: "SELECT Statement",
    metaDescription:
      "The SQL SELECT statement is used to retrieve data from a database.",
  },
  "/documentation/sql/select-distinct-statement": {
    title: "SELECT DISTINCT Statement",
    metaDescription:
      "The SQL SELECT DISTINCT statement is used to retrieve unique values from a database.",
  },
  "/documentation/sql/where-clause": {
    title: "WHERE Clause",
    metaDescription:
      "The SQL WHERE clause is used to filter and conditionally select data from database tables.",
  },
  "/documentation/sql/and-operator": {
    title: "AND Operator",
    metaDescription:
      "The SQL AND operator is used to combine multiple conditions in your queries.",
  },
  "/documentation/sql/or-operator": {
    title: "OR Operator",
    metaDescription:
      "The SQL OR operator is used to create alternative conditions in your database queries.",
  },
  "/documentation/sql/not-operator": {
    title: "NOT Operator",
    metaDescription:
      "The SQL NOT operator is used to negate conditions in your SQL queries.",
  },
  "/documentation/sql/null-values": {
    title: "NULL Values",
    metaDescription:
      "NULL values in SQL represent missing or unknown data in database tables.",
  },
  "/documentation/sql/order-by-clause": {
    title: "ORDER BY Clause",
    metaDescription:
      "The SQL ORDER BY clause is used to sort the results of your queries in ascending or descending order.",
  },
  "/documentation/sql/group-by-clause": {
    title: "GROUP BY Clause",
    metaDescription:
      "The SQL GROUP BY clause is used to group and aggregate data in database queries.",
  },
  "/documentation/sql/aggregate-functions": {
    title: "Aggregate Functions",
    metaDescription:
      "SQL aggregate functions such as SUM, AVG, COUNT, and more are used to summarize data in your queries.",
  },
  "/documentation/sql/having-clause": {
    title: "HAVING Clause",
    metaDescription:
      "The SQL HAVING clause is used to filter the results of a GROUP BY query based on specified conditions.",
  },
  "/documentation/sql/in-operator": {
    title: "IN Operator",
    metaDescription:
      "The SQL IN operator is used to filter results based on a list of specified values.",
  },
  "/documentation/sql/like-operator": {
    title: "LIKE Operator",
    metaDescription:
      "The SQL LIKE operator is used to search for a specified pattern in a column.",
  },
  "/documentation/sql/wildcards": {
    title: "Wildcards",
    metaDescription:
      "Wildcards in SQL, such as % and _, are used with the LIKE operator to perform pattern matching.",
  },
  "/documentation/sql/regex": {
    title: "REGEX",
    metaDescription:
      "Regular Expressions (REGEX) in SQL are used for advanced pattern matching in text data.",
  },
  "/documentation/sql/aliases": {
    title: "Aliases",
    metaDescription:
      "Aliases in SQL are used to give a table or column a temporary name for the duration of a query.",
  },
  "/documentation/sql/between-operator": {
    title: "BETWEEN Operator",
    metaDescription:
      "The SQL BETWEEN operator is used to filter results within a specified range of values.",
  },
  "/documentation/sql/any-operator": {
    title: "ANY Operator",
    metaDescription:
      "The SQL ANY operator is used to compare a value to a set of values.",
  },
  "/documentation/sql/all-operator": {
    title: "ALL Operator",
    metaDescription:
      "The SQL ALL operator is used to compare a value to all values in a set.",
  },
  "/documentation/sql/join-clause": {
    title: "JOIN Clause",
    metaDescription:
      "The SQL JOIN clause is used to combine rows from multiple tables based on a related column between them.",
  },
  "/documentation/sql/inner-join": {
    title: "INNER JOIN",
    metaDescription:
      "The SQL INNER JOIN is used to retrieve records with matching values from both tables.",
  },
  "/documentation/sql/left-join-(left-outer-join)": {
    title: "LEFT JOIN (LEFT OUTER JOIN)",
    metaDescription:
      "The SQL LEFT JOIN (LEFT OUTER JOIN) retrieves all records from the left table and the matched records from the right table.",
  },
  "/documentation/sql/right-join-(right-outer-join)": {
    title: "RIGHT JOIN (RIGHT OUTER JOIN)",
    metaDescription:
      "The SQL RIGHT JOIN (RIGHT OUTER JOIN) retrieves all records from the right table and the matched records from the left table.",
  },
  "/documentation/sql/full-join-(full-outer-join)": {
    title: "FULL OUTER JOIN",
    metaDescription:
      "The SQL FULL OUTER JOIN retrieves all records when there is a match in either the left or right table.",
  },
  "/documentation/sql/union-operator": {
    title: "UNION Operator",
    metaDescription:
      "The SQL UNION operator is used to combine the result sets of two or more SELECT statements into a single result set.",
  },
  "/documentation/sql/intersect-operator": {
    title: "INTERSECT Operator",
    metaDescription:
      "The SQL INTERSECT operator is used to return the common rows between the result sets of two SELECT statements.",
  },
  "/documentation/sql/except-(minus)-operator": {
    title: "EXCEPT (MINUS) Operator",
    metaDescription:
      "The SQL EXCEPT (MINUS) operator is used to return the distinct rows in the result set of the first SELECT statement that are not present in the result set of the second SELECT statement.",
  },
  "/documentation/sql/insert-into-statement": {
    title: "INSERT INTO Statement",
    metaDescription:
      "The SQL INSERT INTO statement is used to add new rows of data into a database table.",
  },
  "/documentation/sql/update-statement": {
    title: "UPDATE Statement",
    metaDescription:
      "The SQL UPDATE statement is used to modify existing records in a database table.",
  },
  "/documentation/sql/delete-statement": {
    title: "DELETE Statement",
    metaDescription:
      "The SQL DELETE statement is used to remove records from a database table.",
  },
  "/documentation/sql/alter-table-statement": {
    title: "ALTER TABLE Statement",
    metaDescription:
      "The SQL ALTER TABLE statement is used to modify an existing database table structure.",
  },
  "/documentation/sql/create-table-statement": {
    title: "CREATE TABLE Statement",
    metaDescription:
      "The SQL CREATE TABLE statement is used to create a new database table.",
  },
  "/documentation/sql/create-index-statement": {
    title: "CREATE INDEX Statement",
    metaDescription:
      "The SQL CREATE INDEX statement is used to create an index on one or more columns of a database table.",
  },
  "/documentation/sql/drop-table-statement": {
    title: "DROP TABLE Statement",
    metaDescription:
      "The SQL DROP TABLE statement is used to delete an existing database table and its data.",
  },
  "/documentation/sql/subqueries-(nested-select-statements)": {
    title: "Subqueries (Nested SELECT Statements)",
    metaDescription:
      "Subqueries in SQL, also known as nested SELECT statements, are queries embedded within another query to retrieve data from the database.",
  },
  "/documentation/sql/case-statement": {
    title: "CASE Statement",
    metaDescription:
      "The SQL CASE statement is used for conditional logic to generate values in query results based on specified conditions.",
  },
  "/documentation/sql/window-functions": {
    title: "Window Functions",
    metaDescription:
      "SQL window functions are used to perform calculations across a set of table rows related to the current row within a query result set.",
  },
  "/documentation/sql/common-table-expressions-(ctes)": {
    title: "Common Table Expressions (CTEs)",
    metaDescription:
      "Common Table Expressions (CTEs) in SQL are temporary result sets that can be referenced within a SELECT, INSERT, UPDATE, or DELETE statement.",
  },
  "/documentation/sql/temporary-tables": {
    title: "Temporary Tables",
    metaDescription:
      "Temporary tables in SQL are used to store and manipulate data temporarily, often within a session or a specific scope.",
  },
  "/documentation/sql/database-transactions": {
    title: "Database Transactions",
    metaDescription:
      "Database transactions in SQL ensure the integrity and consistency of data by grouping one or more SQL statements into a single unit of work.",
  },
  "/documentation/sql/primary-key-constraint": {
    title: "Primary Key Constraint",
    metaDescription:
      "A primary key constraint in SQL ensures that each row in a table is unique and used to identify records in the table.",
  },
  "/documentation/sql/foreign-key-constraint": {
    title: "Foreign Key Constraint",
    metaDescription:
      "A foreign key constraint in SQL enforces referential integrity by linking data in one table to data in another table.",
  },
  "/documentation/sql/unique-constraint": {
    title: "Unique Constraint",
    metaDescription:
      "A unique constraint in SQL enforces the uniqueness of values in one or more columns, ensuring that no duplicate values are allowed.",
  },
  "/documentation/sql/check-constraint": {
    title: "Check Constraint",
    metaDescription:
      "A check constraint in SQL is used to specify conditions that must be met before data can be inserted or updated in a table.",
  },
  "/documentation/sql/indexing-strategies": {
    title: "Indexing Strategies",
    metaDescription:
      "Indexing strategies in SQL involve creating and using indexes to improve the performance of database queries.",
  },
  "/documentation/sql/views": {
    title: "Views",
    metaDescription:
      "SQL views are virtual tables that allow you to simplify complex queries and provide a convenient way to access data from multiple tables.",
  },
  "/documentation/sql/stored-procedures": {
    title: "Stored Procedures",
    metaDescription:
      "SQL stored procedures are precompiled sets of one or more SQL statements that can be executed as a single unit.",
  },
  "/documentation/sql/user-defined-functions-(udfs)": {
    title: "User-Defined Functions (UDFs)",
    metaDescription:
      "User-defined functions (UDFs) in SQL allow you to create custom functions to perform specific tasks within your database queries.",
  },
  "/documentation/sql/triggers": {
    title: "Triggers",
    metaDescription:
      "SQL triggers are special database objects that automatically execute in response to specific events or changes in data.",
  },
  "/documentation/sql/identity-columns-(auto-increment)": {
    title: "Identity Columns",
    metaDescription:
      "Identity columns in SQL are used to automatically generate unique values, typically for primary key fields.",
  },
  "/documentation/sql/substring-functions": {
    title: "Substring Functions",
    metaDescription:
      "SQL substring functions allow you to extract a portion of a string, such as characters or words, from a larger text value.",
  },
  "/documentation/sql/date-and-time-functions": {
    title: "Date and Time Functions",
    metaDescription:
      "SQL date and time functions provide various operations for working with date and time data within your queries.",
  },
  "/documentation/sql/string-functions": {
    title: "String Functions",
    metaDescription:
      "SQL string functions offer a range of operations for manipulating and working with text and character data.",
  },
  "/documentation/sql/mathematical-functions": {
    title: "Mathematical Functions",
    metaDescription:
      "SQL mathematical functions provide capabilities for performing arithmetic and mathematical operations on numeric data.",
  },
  "/documentation/sql/conditional-functions": {
    title: "Conditional Functions",
    metaDescription:
      "SQL conditional functions, like IF and CASE, allow you to make decisions and perform actions based on specified conditions.",
  },
  "/documentation/sql/data-type-conversion": {
    title: "Data Type Conversion",
    metaDescription:
      "SQL data type conversion functions and operations allow you to change the data type of values to fit specific requirements.",
  },
  "/documentation/sql/union-all-operator": {
    title: "UNION ALL Operator",
    metaDescription:
      "The SQL UNION ALL operator is used to combine the result sets of two or more SELECT statements into a single result set, including duplicate rows.",
  },
  "/documentation/sql/exists-subquery": {
    title: "EXISTS Subquery",
    metaDescription:
      "The SQL EXISTS subquery is used to check for the existence of rows in a subquery result set and return true or false.",
  },
  "/documentation/sql/merge-statement-(sql-server)": {
    title: "MERGE Statement",
    metaDescription:
      "The SQL MERGE statement is used to perform insert, update, or delete operations based on specified conditions in a single statement.",
  },
  "/documentation/sql/recursive-queries": {
    title: "Recursive Queries",
    metaDescription:
      "SQL recursive queries allow you to work with hierarchical data structures, such as trees or graphs, by iterating over related records.",
  },
  // -----------------------------------MONGO---------------------------------------
  "/documentation/mongo/find-documents": {
    title: "Find Documents",
    metaDescription:
      "The find operation in MongoDB is used to retrieve documents from a collection. It allows you to specify query conditions to filter the documents you want.",
  },
  "/documentation/mongo/filtering-criteria": {
    title: "Filtering Criteria",
    metaDescription:
      "Filtering criteria in MongoDB are used to narrow down the results when querying a collection, allowing you to retrieve specific documents based on specified conditions.",
  },
  "/documentation/mongo/projections": {
    title: "Projections",
    metaDescription:
      "Projections in MongoDB are used to specify which fields to include or exclude in the query results, allowing you to control the shape of the output.",
  },
  "/documentation/mongo/sorting-results": {
    title: "Sorting Results",
    metaDescription:
      "Sorting results in MongoDB allows you to order the query results in ascending or descending order based on one or more fields in the documents.",
  },
  "/documentation/mongo/limiting-results": {
    title: "Limiting Results",
    metaDescription:
      "Limiting results in MongoDB restricts the number of documents returned by a query, which can be useful to page through large result sets.",
  },
  "/documentation/mongo/skipping-results": {
    title: "Skipping Results",
    metaDescription:
      "Skipping results in MongoDB allows you to skip a specified number of documents in the result set, useful for paginating query results.",
  },
  "/documentation/mongo/counting-documents": {
    title: "Counting Documents",
    metaDescription:
      "Counting documents in MongoDB is done using the `count` or `estimatedDocumentCount` method to determine the number of documents that match a query.",
  },
  "/documentation/mongo/aggregation-framework": {
    title: "Aggregation Framework",
    metaDescription:
      "The MongoDB Aggregation Framework is a powerful tool for data transformation, grouping, and aggregation within collections.",
  },
  "/documentation/mongo/aggregation-operators": {
    title: "Aggregation Operators",
    metaDescription:
      "MongoDB provides a wide range of aggregation operators to perform various operations like filtering, grouping, and reshaping data during aggregation.",
  },
  "/documentation/mongo/insert-documents": {
    title: "Insert Documents",
    metaDescription:
      "Inserting documents in MongoDB is the process of adding new records to a collection, and it can be done using the `insertOne` or `insertMany` methods.",
  },
  "/documentation/mongo/update-documents": {
    title: "Update Documents",
    metaDescription:
      "Updating documents in MongoDB allows you to modify existing records in a collection, and this can be achieved using the `updateOne` or `updateMany` methods.",
  },
  "/documentation/mongo/upsert": {
    title: "Upsert",
    metaDescription:
      "Upsert in MongoDB is a combination of insert and update. It adds a document if it doesn't exist or updates it if it does.",
  },
  "/documentation/mongo/replace-documents": {
    title: "Replace Documents",
    metaDescription:
      "Replacing documents in MongoDB involves entirely replacing an existing document with a new one, effectively updating its content.",
  },
  "/documentation/mongo/delete-documents": {
    title: "Delete Documents",
    metaDescription:
      "Deleting documents in MongoDB allows you to remove records from a collection, and it can be done using the `deleteOne` or `deleteMany` methods.",
  },
  "/documentation/mongo/accessing-null-values": {
    title: "Accessing NULL Values",
    metaDescription:
      "In MongoDB, NULL values represent missing or non-existent fields in documents, and they can be accessed and queried as needed.",
  },
  "/documentation/mongo/index-usage-in-queries": {
    title: "Index Usage in Queries",
    metaDescription:
      "Indexes in MongoDB are crucial for query performance, and understanding their usage in queries is essential for optimizing database operations.",
  },
  "/documentation/mongo/query-optimization": {
    title: "Query Optimization",
    metaDescription:
      "Query optimization in MongoDB involves improving the performance of database queries by creating efficient indexes and structuring queries effectively.",
  },
  "/documentation/mongo/explain-execution-plan": {
    title: "Explain Execution Plan",
    metaDescription:
      "The explain execution plan in MongoDB provides insights into how a query is executed, helping to identify potential performance improvements.",
  },
  "/documentation/mongo/compound-queries": {
    title: "Compound Queries",
    metaDescription:
      "Compound queries in MongoDB involve combining multiple query conditions using logical operators to filter and retrieve documents that meet specific criteria.",
  },
  "/documentation/mongo/logical-operators": {
    title: "Logical Operators",
    metaDescription:
      "Logical operators in MongoDB, such as $and, $or, and $not, are used to combine multiple conditions in a query to filter documents.",
  },
  "/documentation/mongo/comparison-operators": {
    title: "Comparison Operators",
    metaDescription:
      "Comparison operators in MongoDB, like $eq, $ne, $gt, and $lt, allow you to compare values and filter documents based on specified criteria.",
  },
  "/documentation/mongo/array-operators": {
    title: "Array Operators",
    metaDescription:
      "Array operators in MongoDB, such as $in, $all, and $elemMatch, are used to query and filter documents based on conditions related to array fields.",
  },
  "/documentation/mongo/regular-expressions": {
    title: "Regular Expressions",
    metaDescription:
      "Regular expressions in MongoDB are used with the $regex operator to perform advanced pattern matching on string fields in documents.",
  },
  "/documentation/mongo/text-search": {
    title: "Text Search",
    metaDescription:
      "Text search in MongoDB allows you to perform full-text search operations on text fields within documents using the $text operator.",
  },
  "/documentation/mongo/geospatial-queries": {
    title: "Geospatial Queries",
    metaDescription:
      "Geospatial queries in MongoDB enable location-based queries, allowing you to search for documents with coordinates within a specified area.",
  },
  "/documentation/mongo/distinct-values": {
    title: "Distinct Values",
    metaDescription:
      "Distinct values in MongoDB are used to retrieve unique values from a specified field within a collection.",
  },
  "/documentation/mongo/date-and-time-queries": {
    title: "Date and Time Queries",
    metaDescription:
      "Date and time queries in MongoDB involve filtering documents based on date and time values, allowing you to retrieve records within specific time ranges.",
  },
  "/documentation/mongo/case-insensitive-queries": {
    title: "Case-Insensitive Queries",
    metaDescription:
      "Case-insensitive queries in MongoDB allow you to perform searches without being case-sensitive, ensuring that different letter cases are treated as the same.",
  },
  "/documentation/mongo/query-with-array-elements": {
    title: "Query with Array Elements",
    metaDescription:
      "Querying documents with array elements in MongoDB allows you to filter and retrieve records based on conditions related to arrays within documents.",
  },
  "/documentation/mongo/null-vs.-missing-fields": {
    title: "NULL vs. Missing Fields",
    metaDescription:
      "Understanding the difference between NULL and missing fields in MongoDB helps in querying and handling documents effectively, especially when dealing with optional fields.",
  },
  "/documentation/mongo/query-on-embedded-documents": {
    title: "Query on Embedded Documents",
    metaDescription:
      "Querying embedded documents in MongoDB involves filtering and retrieving documents based on conditions related to nested or subdocuments within the main documents.",
  },
  "/documentation/mongo/type-specific-queries": {
    title: "Type-Specific Queries",
    metaDescription:
      "Type-specific queries in MongoDB allow you to filter documents based on data types of fields, ensuring you work with data that matches specific types or conditions.",
  },
};

export default routeData;
