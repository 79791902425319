import QCLogoBlack from "../../assets/qcLogoBlack.png";
import QCLogoWhite from "../../assets/qcLogoWhite.png";
import React, { useState, useEffect } from "react";
import Login from "../../pages/Home/Login";
import "./navbarStyles.css";
import { useNavigate } from "react-router-dom";
import Overlay from "../overlay/Overlay";
import { CLICK, HOME } from "../../data/constants";
import { useCookies } from "react-cookie";
import { eventFrequencyCall } from "../../utils/Util";

export default function Navbar() {
  const [googleConnectionButtonHovered, setGoogleConnectionButtonHovered] =
    useState(false);
  const [, setConnection] = useState(false);
  const [, setUserName] = useState("User");
  const [activePromises, setActivePromises] = useState(0);
  const [isBlackButton, setIsBlackButton] = useState(false);
  // const { cookieExists, setCookieExists } = useCookieContext();
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const mobileMq = window.matchMedia(
      "(max-width: 767px) and (orientation: portrait)"
    );
    setIsMobileScreen(mobileMq.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobileScreen(event.matches);
    };

    mobileMq.addListener(handleMediaQueryChange);

    return () => {
      mobileMq.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMouseOver = () => {
    setGoogleConnectionButtonHovered(true);
  };

  const handleMouseOut = () => {
    setGoogleConnectionButtonHovered(false);
  };

  return (
    <div className="nav-bar">
      <Overlay active={activePromises > 0} />
      <img
        className="nav-bar-logo hover-effect"
        id="logo"
        alt="query-convert-logo-png"
        src={QCLogoWhite}
        loading="lazy"
      />
      <Login 
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
        googleConnectionButtonHovered={googleConnectionButtonHovered}
        isBlackButton={isBlackButton}
      />
    </div>
  );
}

export function NavbarHomeBtn({
  isLogoBlack,
  isHomeBlack,
  isBgLight,
  isBgDark,
  source,
}) {
  const [cookies, setCookie, removeCookie] = useCookies(["googleId"]);

  const homeHandle = () => {
    eventFrequencyCall(cookies.googleId, source, CLICK, HOME);
  };

  return (
    <div className={`nav-bar ${isBgLight ? "light" : isBgDark ? "dark" : ""}`}>
      <img
        className="nav-bar-logo hover-effect"
        id="logo"
        alt="query-convert-logo-png"
        src={isLogoBlack ? QCLogoBlack : QCLogoWhite}
        loading="lazy"
      />
      <a href="/" className="">
        <button
          onClick={homeHandle}
          type="button"
          className={`nav-bar-home-btn${isHomeBlack ? " black" : " white"}`}
        >
          Home
        </button>
      </a>
    </div>
  );
}
