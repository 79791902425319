const lightThemeVariables = {
    "--sql-doc-background-conic-gradient": "white",
    "--sql-doc-text-format-bg-color": "#f5f5f5",
    "--sql-doc-text-format-code-block-color": "#ff4081",
    "--sql-doc-text-format": "#ff8e4e",
    "--sql-doc-text-format-code-block-constants-color": "#007bff",
    "--sql-doc-side-bar-color": "#dddddd",
    "--sql-doc-side-bar-border-color": "#333333",
    "--sql-doc-side-bar-bg-color": "transparent",
    "--sql-doc-side-bar-webkit-scrollbar-thumb-color": "#333333",
    "--sql-doc-side-bar-webkit-scrollbar-track-color": "transparent",
    "--sql-doc-content-color": "black",
    "--sql-doc-content-selection-bg-color": "black",
    "--sql-doc-content-selection-color": "white",
    "--sql-doc-textarea-bg-color": "#f5f5f5",
    "--sql-doc-textarea-color": "black",
    "--sql-doc-textarea-border-color": "#333333",
    "--sql-doc-how-to-query-btn-bg-color": "#007bff",
    "--sql-table-content-color": "#333333",
    "--sql-table-content-bg-color": "#f5f5f5",
    "--sql-table-content-border-color": "#cccccc",
    "--sql-table-content-row-border-color": "#cccccc",
    "--sql-back-btn-bg-color": "#007bff",
    "--sql-table-compare-bg-color": "#eeeeee",
    "--sql-table-compare-border-color": "#dddddd",
    "--sql-table-compare-row-border-color": "#dddddd",
    "--sql-doc-compare-model-bg-color": "rgba(255, 255, 255, 0.5)",
    "--sql-doc-compare-model-content-bg-color": "black",
    "--sql-doc-compare-model-content-color": "white",
    "--sql-doc-compare-model-content-selection-bg-color": "white",
    "--sql-doc-compare-model-content-selection-color": "black",
    "--sql-add-folder-color": "black",
    "--sql-add-folder-bg-color": "transparent",
    "--sql-add-folder-hover-color": "white",
    "--sql-add-folder-hover-bg-color": "#ccc",
    "--sql-add-folder-selected-btn-bg-color": "black",
    "--sql-add-folder-selected-btn-color": "white",
    "--sql-doc-heading-curved-underline-bg-gradient":
      "linear-gradient(to right, #888, #111)",
    "--sql-li-color": "black",
    "--sql-doc-mobile-select-btn-bg-color": "transparent",
    "--sql-doc-mobile-select-btn-color": "black",
    "--sql-doc-side-bar-enable-bg-color": "#f5f5f5",
    "--sql-doc-mobile-prev-btn-bg-color": "transparent",
    "--sql-doc-mobile-prev-btn-color": "black",
    "--sql-doc-mobile-next-btn-bg-color": "transparent",
    "--sql-doc-mobile-next-btn-color": "black",
    "--sql-doc-bottom-button-next-prev-bg-color": "black",
    "--sql-doc-bottom-button-next-prev-color": "white",
    "--sql-add-folder-selected-btn-bg-color-hovered": "rgba(35,35,35,0.8)",





    "--saved-queries-bg-color": "white",
    "--saved-queries-side-bar-color": "#ffffff",
    "--saved-queries-side-bar-border-color": "#000000",
    "--saved-queries-no-folder-icon": "#000",
    "--saved-queries-side-bar-scroll-bar-color": "white",
    "--saved-queries-side-bar-scroll-bar-thumb-color": "white",
    "--saved-queries-side-bar-scroll-bar-track-color": "transparent",
    "--saved-queries-content-color": "black",
    "--saved-queries-textarea-color": "black",
    "--saved-queries-textarea-border-color": "black",
    "--saved-queries-textarea-bg-color": "#ffffff",
    "--saved-queries-textarea-scroll-bar-color": "transparent",
    "--saved-queries-textarea-scroll-bar-thumb-color": "#222",
    "--saved-queries-textarea-scroll-bar-track-color": "transparent",
    "--saved-queries-querySelector-selection-bg-color": "black",
    "--saved-queries-querySelector-selection-color": "white",
    "--saved-queries-popup-content-bg-color": "#ffffff",
    "--saved-queries-popup-content-color": "black",
    "--saved-queries-popup-content-selection-bg-color": "white",
    "--saved-queries-popup-content-selection-color": "black",
    "--saved-queries-popup-input-border-color": "#000",
    "--saved-queries-hr-border-color": "#000",
    "--saved-queries-dropdown-right-click-bg-color": "#ffffff",
    "--saved-queries-dropdown-right-click-box-shadow-color": "rgba(0, 0, 0, 0.2)",
    "--saved-queries-add-btn-bg-color": "black",
    "--saved-queries-add-btn-text-color":"white",
    "--saved-queries-add-btn-border-color": "black",
    "--saved-queries-add-btn-color": "black",
    "--saved-queries-save-btn-bg-color":"transparent",
    "--saved-queries-save-btn-border-color":"black",
    "--saved-queries-create-folder-btn-bg-color": "black",
    "--saved-queries-create-folder-btn-border-color": "white",
    "--saved-queries-create-folder-btn-color": "white",
    "--saved-queries-create-folder-btn-hover-border-color": "white",
    "--saved-queries-create-folder-btn-hover-bg-color": "rgba(0, 0, 0, 0.9)",
    "--saved-queries-create-folder-btn-hover-color": "white",
    "--saved-queries-btn-add-folder-bg-color":"black",
    "--saved-queries-btn-add-folder-color":"white",
    "--saved-queries-add-btn-text-hover-color":"#ccc",
    "--saved-queries-add-btn-color": "black",
    "--saved-queries-add-btn-selected-color":"black",
    "--saved-queries-folder-btn-text-color-active":"white"

  };

  export default lightThemeVariables;