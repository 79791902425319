const darkThemeVariables = {
    "--sql-doc-background-conic-gradient":
      "conic-gradient(from 280deg at 100% 0%, rgba(180, 180, 180, 100) 90deg, rgba(0, 0, 0, 100) 360deg)",
    "--sql-doc-text-format-bg-color": "rgba(222, 222, 222, 0.1)",
    "--sql-doc-text-format-code-block-color": "#c24f7d",
    "--sql-doc-text-format": "#dd7934",
    "--sql-doc-text-format-code-block-constants-color": "#40a8e0",
    "--sql-doc-side-bar-color": "#cccccc",
    "--sql-doc-side-bar-border-color": "#333333",
    "--sql-doc-side-bar-bg-color": "transparent",
    "--sql-doc-side-bar-webkit-scrollbar-thumb-color": "white",
    "--sql-doc-side-bar-webkit-scrollbar-track-color": "transparent",
    "--sql-doc-content-color": "white",
    "--sql-doc-content-selection-bg-color": "white",
    "--sql-doc-content-selection-color": "black",
    "--sql-doc-textarea-bg-color": "#222222",
    "--sql-doc-textarea-color": "white",
    "--sql-doc-textarea-border-color": "white",
    "--sql-doc-how-to-query-btn-bg-color": "#f5f5f5",
    "--sql-table-content-color": "#fff",
    "--sql-table-content-bg-color": "#222",
    "--sql-table-content-border-color": "#ccc",
    "--sql-table-content-row-border-color": "#ccc",
    "--sql-back-btn-bg-color": "#f5f5f5",
    "--sql-table-compare-bg-color": "#eeeeee",
    "--sql-table-compare-border-color": "#222222",
    "--sql-table-compare-row-border-color": "#222222",
    "--sql-doc-compare-model-bg-color": "rgba(0, 0, 0, 0.5)",
    "--sql-doc-compare-model-content-bg-color": "white",
    "--sql-doc-compare-model-content-color": "black",
    "--sql-doc-compare-model-content-selection-bg-color": "black",
    "--sql-doc-compare-model-content-selection-color": "white",
    "--sql-add-folder-color": "white",
    "--sql-add-folder-bg-color": "transparent",
    "--sql-add-folder-hover-color": "black",
    "--sql-add-folder-hover-bg-color": "#ddd",
    "--sql-add-folder-selected-btn-bg-color": "white",
    "--sql-add-folder-selected-btn-color": "black",
    "--sql-doc-heading-curved-underline-bg-gradient":
      "linear-gradient(to right, #ccc, #888)",
    "--sql-li-color": "white",
    "--sql-doc-mobile-select-btn-bg-color": "transparent",
    "--sql-doc-mobile-select-btn-color": "white",
    "--sql-doc-side-bar-enable-bg-color": "#111",
    "--sql-doc-mobile-prev-btn-bg-color": "transparent",
    "--sql-doc-mobile-prev-btn-color": "white",
    "--sql-doc-mobile-next-btn-bg-color": "transparent",
    "--sql-doc-mobile-next-btn-color": "white",
    "--sql-doc-bottom-button-next-prev-bg-color": "#f5f5f5",
    "--sql-doc-bottom-button-next-prev-color": "black",
    "--sql-add-folder-selected-btn-bg-color-hovered": "rgba(220,220,220,0.8)",





    "--saved-queries-bg-color": "conic-gradient(from 280deg at 100% 0%, rgba(180, 180, 180, 100) 90deg, rgba(0, 0, 0, 100) 360deg)",
    "--saved-queries-side-bar-color": "#cccccc",
    "--saved-queries-side-bar-border-color": "#333333",
    "--saved-queries-no-folder-icon": "#ccc",
    "--saved-queries-side-bar-scroll-bar-color": "white",
    "--saved-queries-side-bar-scroll-bar-thumb-color": "white",
    "--saved-queries-side-bar-scroll-bar-track-color": "transparent",
    "--saved-queries-add-btn-color": "#f5f5f5",
    "--saved-queries-content-color": "white",
    "--saved-queries-textarea-color": "white",
    "--saved-queries-textarea-border-color": "white",
    "--saved-queries-textarea-bg-color": "#222",
    "--saved-queries-textarea-scroll-bar-color": "transparent",
    "--saved-queries-textarea-scroll-bar-thumb-color": "white",
    "--saved-queries-querySelector-selection-bg-color": "white",
    "--saved-queries-querySelector-selection-color": "black",
    "--saved-queries-popup-content-bg-color": "#f8f9fa",
    "--saved-queries-popup-content-color": "black",
    "--saved-queries-popup-content-selection-bg-color": "black",
    "--saved-queries-popup-content-selection-color": "white",
    "--saved-queries-popup-input-border-color": "#ccc",
    "--saved-queries-hr-border-color": "#ccc",
    "--saved-queries-dropdown-right-click-bg-color": "#f8f9fa",
    "--saved-queries-dropdown-right-click-box-shadow-color": "rgba(0, 0, 0, 0.2)",
    "--saved-queries-add-btn-bg-color": "white",
    "--saved-queries-add-btn-text-color":"black",
    "--saved-queries-add-btn-border-color": "white",
    "--saved-queries-add-btn-color": "white",
    "--saved-queries-save-btn-bg-color":"white",
    "--saved-queries-save-btn-border-color":"white",
    "--saved-queries-create-folder-btn-bg-color": "#f5f5f5",
    "--saved-queries-create-folder-btn-border-color": "white",
    "--saved-queries-create-folder-btn-color": "black",
    "--saved-queries-create-folder-btn-hover-border-color": "white",
    "--saved-queries-create-folder-btn-hover-bg-color": "rgba(0, 0, 0, 0.3)",
    "--saved-queries-create-folder-btn-hover-color": "white",
    "--saved-queries-btn-add-folder-bg-color":"#f5f5f5",
    "--saved-queries-btn-add-folder-color":"black",
    "--saved-queries-add-btn-text-hover-color":"#222222",
    "--saved-queries-add-btn-selected-color":"#f5f5f5",
    "--saved-queries-folder-btn-text-color-active":"black"

  };

  export default darkThemeVariables;
