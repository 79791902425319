import { useEffect } from "react";

const useAutosizeTextArea = (textAreaRefs, values) => {
  useEffect(() => {
    textAreaRefs.forEach((textAreaRef, index) => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "0px";
        const scrollHeight = textAreaRef.current.scrollHeight;
        textAreaRef.current.style.height = scrollHeight + "px";
      }
    });
  }, [textAreaRefs, values]);
};

export default useAutosizeTextArea;
