import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./sqlDocumentationStyles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sqlKeywords from "../../data/sqlKeywords";
import mongoDBKeywords from "../../data/mongoDBKeywords";
import lightThemeVariables from "../../data/lightThemeVariables";
import darkThemeVariables from "../../data/darkThemeVariables";
import variablePrefixes from "../../data/variablePrefixes";
import { useParams, useNavigate } from "react-router-dom";
import featureFlags from "../../components/featureFlags";
import {
  faXmark,
  faBars,
  faChevronLeft,
  faChevronRight,
  faCircleHalfStroke,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Overlay from "../../components/overlay/Overlay";
import { NavbarHomeBtn } from "../../components/navbar/Navbar";
import SvgViewer from "../../utils/SvgViewer";
import { useCookies } from "react-cookie";
import {
  CLICK,
  SIDE_BAR,
  NEXT,
  PREVIOUS,
  SQL,
  VIEW,
  MONGODB,
  BACKEND_URL,
} from "../../data/constants";
import { eventFrequencyCall } from "../../utils/Util";
import routeData from "../../utils/RouteData";

export default function SqlDocumentation() {
  const [showCompareModal, setShowQueryModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folders, setFolders] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(1);
  const [activePromises, setActivePromises] = useState(0);
  const [showMobileList, setShowMobileList] = useState(false);
  const [isSelectFolderIdSet, setIsSelectedFolderIdSet] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [svgContent, setSvgContent] = useState(
    '<svg width="100" height="100"><circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" /></svg>'
  );
  const [cookies, setCookie, removeCookie] = useCookies(["googleId"]);
  const googleIdFromCookies = cookies.googleId;
  const queryLanguage = window.location.href.split("/")[4];

  const eventFrequencyCallByQueryLangugage = (
    googleId,
    action,
    targetElement
  ) => {
    console.log(queryLanguage);
    if (queryLanguage === "sql")
      eventFrequencyCall(googleId, SQL, action, targetElement);
    else eventFrequencyCall(googleId, MONGODB, action, targetElement);
  };

  useEffect(() => {
    eventFrequencyCallByQueryLangugage(googleIdFromCookies, VIEW, "");
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchFolders();
  }, []);

  useEffect(() => {
    fetchFolderData(folders);
  }, [selectedFolderId]);

  useEffect(() => {
    if (selectedFolderId >= 1 && selectedFolderId < folders.length) {
      navigate(
        `/documentation/${queryLanguage}/${folders[
          selectedFolderId - 1
        ].folderName
          .toLowerCase()
          .replace(/ /g, "-")}`
      );
    }
  }, [selectedFolderId]);

  const [title, setTitle] = useState("Documentation - Learn from Basics to Advanced");
  const [metaDescription, setMetaDescription] = useState("Explore our documentation to master the DB languages from basics to advanced topics");

  useEffect(() => {
    if (selectedFolderId >= 1 && selectedFolderId <= folders.length) {
      const language = window.location.href.split("/")[4];
      const topic = window.location.href.split("/")[5];
      const routeKey = `/documentation/${language}/${topic}`;
      const newRouteData = routeData[routeKey];

      if (newRouteData === undefined) {
        setTitle("SQL Documentation - Learn SQL from Basics to Advanced");
        setMetaDescription("Explore our SQL documentation to master the SQL language from basics to advanced topics");
      } else {
        setTitle(newRouteData.title);
        setMetaDescription(newRouteData.metaDescription);
      }
    }
  }, [selectedFolderId, folders, routeData]);

  const startPromise = () => {
    setActivePromises((prev) => prev + 1);
  };

  const endPromise = () => {
    setActivePromises(0);
  };

  const [isDarkTheme, setIsDarkTheme] = useState(true);

  const toggleTheme = () => {
    setIsDarkTheme((prevTheme) => !prevTheme);
  };

  const themeVariables = isDarkTheme ? darkThemeVariables : lightThemeVariables;
  Object.entries(themeVariables).forEach(([key, value]) => {
    document.documentElement.style.setProperty(key, value);
  });

  const fetchFolders = async () => {
    startPromise();

    axios
      .get(`${BACKEND_URL}/api/${queryLanguage}folders`)
      .then((response) => {
        setTimeout(() => {
          setFolders(response.data);
          fetchFolderData(response.data);
          endPromise();
        }, 0);
      })
      .catch((error) => {
        setTimeout(() => {
          console.error("Error fetching folders:", error);
          endPromise();
        }, 0);
      });
  };

  const fetchFolderData = async (foldersResponse) => {
    if (foldersResponse) {
      var paramFolder = await foldersResponse.filter(
        (folder) => folder.folderName.toLowerCase().replace(/ /g, "-") === id
      );
      if (paramFolder && paramFolder[0]) {
        if (!isSelectFolderIdSet) {
          setSelectedFolderId(paramFolder[0].folderId);
          axios
            .get(
              `${BACKEND_URL}/api/${queryLanguage}folders/${paramFolder[0].folderId}`
            )
            .then((response) => {
              setSelectedFolder(response.data[0]);
              window.scrollTo(0, 0);
            })
            .catch((error) => {
              console.error("Error fetching folderData:", error);
            });
        } else {
          setSelectedFolderId(selectedFolderId);
          axios
            .get(
              `${BACKEND_URL}/api/${queryLanguage}folders/${selectedFolderId}`
            )
            .then((response) => {
              setSelectedFolder(response.data[0]);
              window.scrollTo(0, 0);
            })
            .catch((error) => {
              console.error("Error fetching folderData:", error);
            });
        }
      }
    }
  };

  const goToNextPage = () => {
    if (selectedFolderId < folders.length) {
      setIsSelectedFolderIdSet(true);
      setSelectedFolderId((prevState) => prevState + 1);
      const folderContainer = document.getElementById("doc-side-bar-id");
      if (folderContainer) {
        folderContainer.scrollTop =
          folderContainer.scrollTop + folderContainer.clientHeight / 25;
      }
      if (
        folders[selectedFolderId] &&
        folders[selectedFolderId].folderName !== undefined
      )
        navigate(
          `/documentation/${queryLanguage}/${folders[
            selectedFolderId
          ].folderName
            .toLowerCase()
            .replace(/ /g, "-")}`
        );
    }
    eventFrequencyCallByQueryLangugage(googleIdFromCookies, CLICK, NEXT);
  };

  const goToPrevPage = () => {
    if (selectedFolderId > 1) {
      setIsSelectedFolderIdSet(true);
      setSelectedFolderId((prevState) => prevState - 1);
      const folderContainer = document.getElementById("doc-side-bar-id");
      if (folderContainer) {
        folderContainer.scrollTop =
          folderContainer.scrollTop - folderContainer.clientHeight / 25;
      }
      if (
        folders[selectedFolderId] &&
        folders[selectedFolderId].folderName !== undefined
      )
        navigate(
          `/documentation/${queryLanguage}/${folders[
            selectedFolderId
          ].folderName
            .toLowerCase()
            .replace(/ /g, "-")}`
        );
    }
    eventFrequencyCallByQueryLangugage(googleIdFromCookies, CLICK, PREVIOUS);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.shiftKey && e.key === "ArrowDown") {
        goToNextPage();
      } else if (e.shiftKey && e.key === "ArrowUp") goToPrevPage();
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedFolderId, setIsSelectedFolderIdSet, folders]);

  const handleClick = (folder) => {
    setSelectedFolderId(folder.folderId);
    navigate(
      `/documentation/${queryLanguage}/${folder.folderName
        .toLowerCase()
        .replace(/ /g, "-")}`
    );
    setShowMobileList(!showMobileList);
    eventFrequencyCallByQueryLangugage(googleIdFromCookies, CLICK, SIDE_BAR);
  };

  const convertContentToLines = (content) => {
    return content.split("\n").filter((line) => line.trim() !== "");
  };

  const toggleMobileList = () => {
    setShowMobileList(!showMobileList);
  };

  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        event.target.className !== "sql-doc-mobile-select-btn" &&
        event.target.className.baseVal !== "svg-inline--fa fa-bars plus-icon"
      ) {
        setShowMobileList(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const highlightKeywords = (text) => {
    let keywordsPattern = "";
    if (queryLanguage === "sql")
      keywordsPattern = new RegExp(`\\b(${sqlKeywords.join("|")})\\b`, "g");
    else
      keywordsPattern = new RegExp(`\\b(${mongoDBKeywords.join("|")})\\b`, "g");

    const parts = text.split(/(\s+|\b|'[^']*'|(?<=\w)\(|\)(?=\w))/);

    const highlightedWords = parts.map((part, index) => {
      const isKeyword = part.match(keywordsPattern);
      if (isKeyword) {
        return (
          <span key={index} className="sql-doc-text-format">
            {part}
          </span>
        );
      } else {
        if (
          variablePrefixes.some((prefix) =>
            part.toLowerCase().startsWith(prefix)
          )
        ) {
          return (
            <span key={index} className="sql-doc-text-format-variable">
              {part}
            </span>
          );
        } else {
          return part;
        }
      }
    });

    return highlightedWords;
  };
  const highlightKeywordsCodeBlock = (text) => {
    let keywordsPattern = "";
    if (queryLanguage === "sql")
      keywordsPattern = new RegExp(`\\b(${sqlKeywords.join("|")})\\b`, "g");
    else
      keywordsPattern = new RegExp(`\\b(${mongoDBKeywords.join("|")})\\b`, "g");

    const parts = text.split(/(\s+|\b(?![^']*')|'[^']*')/);

    const highlightedWords = parts.map((part, index) => {
      const isKeyword = part.match(keywordsPattern);
      if (isKeyword) {
        return (
          <span key={index} className="sql-doc-text-format-code-block keyword">
            {part}
          </span>
        );
      } else {
        if (
          variablePrefixes.some((prefix) =>
            part.toLowerCase().startsWith(prefix)
          )
        ) {
          return (
            <span
              key={index}
              className="sql-doc-text-format-code-block-variable"
            >
              {part}
            </span>
          );
        } else if (
          /^\d+$/.test(part) ||
          (part.startsWith("'") && part.endsWith("'"))
        ) {
          return (
            <span
              key={index}
              className="sql-doc-text-format-code-block-constants"
            >
              {part}
            </span>
          );
        } else {
          return part;
        }
      }
    });

    return highlightedWords;
  };

  const handleComponentType = (query, index) => {
    switch (query.type) {
      case 1:
        return <p key={index}>{highlightKeywords(query.value)}</p>;
      case 2:
        return (
          <p
            spellCheck="false"
            style={{ whiteSpace: "pre-line", lineHeight: "1.5" }}
            className="sql-doc-textarea"
            readOnly={true}
            rows={1}
            key={index}
          >
            {highlightKeywordsCodeBlock(query.value.replace(/\\n/g, "\n"))}
          </p>
        );
      case 3:
        return (
          <>
            <p
              spellCheck="false"
              style={{ whiteSpace: "pre-line", lineHeight: "1.5" }}
              className="sql-doc-textarea"
              readOnly={true}
              rows={1}
              key={index}
            >
              {highlightKeywordsCodeBlock(query.value.replace(/\\n/g, "\n"))}
            </p>
            {showCompareModal && (
              <div className="sql-doc-compare-model">
                <div className="sql-doc-compare-model-content">
                  <button
                    type="button"
                    className="faXMark"
                    onClick={() => setShowQueryModal(false)}
                  >
                    <FontAwesomeIcon className="" icon={faXmark} />
                  </button>
                  <p
                    className="sql-doc-popup"
                    style={{
                      whiteSpace: "pre-wrap",
                      fontFamily: "inherit",
                      fontSize: "inherit",
                    }}
                  >
                    <table className="sql-table-compare">
                      <thead>
                        <tr>
                          <th className="sql-table-compare-row" key="SQL">
                            SQL
                          </th>
                          <th className="sql-table-compare-row" key="MongoDB">
                            MongoDB
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {query.compareQuery.map((query, index) => (
                          <tr key={index}>
                            <td className="sql-table-compare-row">
                              {query.SQL}
                            </td>
                            <td className="sql-table-compare-row">
                              {query.MongoDB}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </p>
                </div>
              </div>
            )}
          </>
        );
      case 4:
        return (
          <>
            {query.heading && (
              <h4 className="sql-doc-heading-curved-underline">
                {query.heading}
              </h4>
            )}

            {query.subHeading ? (
              <p style={{ margin: "0px", padding: "0px" }}>
                {query.subHeading}
              </p>
            ) : null}
            <p
              className="sql-doc-pre-bullets"
              style={{ margin: "0px", padding: "0px" }}
            >
              {convertContentToLines(query.value).map((line, index) => (
                <li className="sql-li" key={index}>
                  {highlightKeywords(line.trim())}
                </li>
              ))}
            </p>
          </>
        );
      case 5:
        return (
          <>
            {query.tableHeading && (
              <h4 className="sql-doc-heading-curved-underline">
                {query.tableHeading}
              </h4>
            )}

            {query.tableDesc && <p>{query.tableDesc}</p>}
            <div className="sql-table-container">
              <table className="sql-table-content">
                <thead>
                  <tr>
                    {query.fields.map((info, index) => (
                      <th className="sql-table-content-row" key={index}>
                        {info}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {query.table.map((tableInfo, index) => (
                    <tr key={index}>
                      {query.fields.map((field, fieldIndex) => (
                        <td key={fieldIndex} className="sql-table-content-row">
                          {tableInfo[field]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );
      case 6:
        return (
          <>
            {query.topicHeading && (
              <h4 className="sql-doc-heading-curved-underline">
                {query.topicHeading}
              </h4>
            )}
            {query.topicBullets ? (
              <p
                className="sql-doc-pre-bullets"
                style={{ margin: "0px", padding: "0px" }}
              >
                {convertContentToLines(query.topicBullets).map(
                  (line, index) => (
                    <li className="sql-li" key={index}>
                      {highlightKeywords(line.trim())}
                    </li>
                  )
                )}
              </p>
            ) : null}

            {query.topicDesc ? (
              <p>{highlightKeywords(query.topicDesc)}</p>
            ) : null}
            {query.topicCodeBlock ? (
              <p
                spellCheck="false"
                style={{ whiteSpace: "pre-line", lineHeight: "1.5" }}
                className="sql-doc-textarea "
                readOnly={true}
                rows={1}
              >
                {highlightKeywordsCodeBlock(
                  query.topicCodeBlock.replace(/\\n/g, "\n")
                )}
              </p>
            ) : null}

            {query.pair
              ? query.pair.map((query, index) => (
                  <>
                    <li className="sql-li" key={index}>
                      {query.key}
                    </li>
                    {query.value && (
                      <p
                        spellCheck="false"
                        style={{ whiteSpace: "pre-line", lineHeight: "1.5" }}
                        className="sql-doc-textarea"
                        readOnly={true}
                        rows={1}
                      >
                        {highlightKeywordsCodeBlock(
                          query.value.replace(/\\n/g, "\n")
                        )}
                      </p>
                    )}
                  </>
                ))
              : null}

            {query.pairBullets
              ? query.pairBullets.map((query, index) => (
                  <>
                    {query.keySubHeading && (
                      <h4
                        key={index}
                        className="sql-doc-heading-curved-underline"
                        // style={{ marginTop: "30px" }}
                      >
                        {query.keySubHeading}
                      </h4>
                    )}
                    <p
                      className=""
                      style={{
                        whiteSpace: "pre-wrap",
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        lineHeight: "2.5",
                        marginBottom: "-5px",
                      }}
                    >
                      {convertContentToLines(query.key).map((line, index) => (
                        <li className="sql-li" key={index}>
                          {highlightKeywords(line.trim())}
                        </li>
                      ))}
                    </p>
                    {query.value && (
                      <p
                        spellCheck="false"
                        style={{ whiteSpace: "pre-line", lineHeight: "1.5" }}
                        className="sql-doc-textarea"
                        readOnly={true}
                        rows={1}
                      >
                        {highlightKeywordsCodeBlock(
                          query.value.replace(/\\n/g, "\n")
                        )}
                      </p>
                    )}
                  </>
                ))
              : null}

            {query.conclude &&
              convertContentToLines(query.conclude).map((line, index) => (
                <li className="sql-li" key={index}>
                  {highlightKeywords(line.trim())}
                </li>
              ))}
          </>
        );
      case 7:
        return <SvgViewer svgContent={query.value} />;
      case 8:
        return (
          <img
            src={`../../${isDarkTheme ? "dark" : "light"}/${query.value}`}
            className="sql-doc-diagrams"
            alt="SQL Diagram"
          />
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className="sql-doc-main-container">
        {isDarkTheme ? (
          <NavbarHomeBtn
            isBgDark={true}
            isLogoBlack={false}
            source={SQL}
          ></NavbarHomeBtn>
        ) : (
          <NavbarHomeBtn
            isBgLight={true}
            isLogoBlack={true}
            isHomeBlack={true}
            source={SQL}
          ></NavbarHomeBtn>
        )}

        <div
          id="doc-side-bar-id"
          ref={sidebarRef}
          className={`sql-doc-side-bar ${
            showMobileList ? "sql-doc-side-bar-enable" : ""
          }`}
        >
          {folders.map((folder) => (
            <button
              key={folder.folderId}
              type="button"
              className={`sql-add-folder ${
                selectedFolderId === folder.folderId ? "sql-selected-btn" : ""
              }`}
              onClick={() => handleClick(folder)}
            >
              <span className="sql-add-folder-span">{folder.folderName}</span>
            </button>
          ))}
        </div>
        <div className="sql-doc-content">
          <Overlay active={activePromises > 0} component="SqlDocumentation" />
          <div className="sql-doc-mobile-select">
            <button
              className="sql-doc-mobile-select-btn"
              onClick={toggleMobileList}
            >
              <FontAwesomeIcon icon={faBars} className="plus-icon" />
            </button>

            <div className="sql-doc-mobile-prev-next-div">
              <button
                className="sql-doc-bottom-button-previous-mobile"
                onClick={goToPrevPage}
              >
                <FontAwesomeIcon
                  className="convert-query-left-container-convert-btn-icon-mobile-left-arrow"
                  icon={faArrowLeft}
                />
                <span>Previous</span>
              </button>
              <button
                className="sql-doc-bottom-button-next-mobile"
                onClick={goToNextPage}
              >
                <span>Next</span>
                <FontAwesomeIcon
                  className="convert-query-left-container-convert-btn-icon-mobile-right-arrow"
                  icon={faArrowRight}
                />
              </button>
            </div>

            {/* <button className="sql-doc-mobile-prev-btn" onClick={goToPrevPage}>
            <FontAwesomeIcon icon={faChevronLeft} className="plus-icon" />
          </button>
          <button className="sql-doc-mobile-next-btn" onClick={goToNextPage}>
            <FontAwesomeIcon icon={faChevronRight} className="plus-icon" />
          </button> */}
          </div>
          {selectedFolder && (
            <>
              <div className="sql-doc-main-content">
                <div>
                  <label className="sql-doc-heading sql-doc-heading-curved-underline hovered">
                    {selectedFolder.folderName}
                  </label>
                  {featureFlags.docTopBtns && (
                    <>
                      <div className="sql-doc-top-buttons">
                        <button
                          className="sql-doc-bottom-button-previous"
                          onClick={goToPrevPage}
                        >
                          <FontAwesomeIcon
                            className="convert-query-left-container-convert-btn-icon"
                            icon={faArrowLeft}
                          />
                          <span>Previous</span>
                        </button>
                        <button
                          className="sql-doc-bottom-button-next"
                          onClick={goToNextPage}
                        >
                          <span>Next</span>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                      </div>
                      <hr className="sql-doc-hr" />
                    </>
                  )}
                </div>

                {selectedFolder.description.map((query, index) => {
                  return handleComponentType(query, index);
                })}
              </div>
              <div className="sql-doc-bottom-buttons">
                <button
                  className="sql-doc-bottom-button-previous"
                  onClick={goToPrevPage}
                >
                  <FontAwesomeIcon
                    className="convert-query-left-container-convert-btn-icon"
                    icon={faArrowLeft}
                  />
                  <span>Previous</span>
                </button>
                <button
                  className="sql-doc-bottom-button-next"
                  onClick={goToNextPage}
                >
                  <span>Next</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>

              <div className="sql-doc-mobile-prev-next-div-bottom-btns">
                <button
                  className="sql-doc-bottom-button-previous-mobile"
                  onClick={goToPrevPage}
                >
                  <FontAwesomeIcon
                    className="convert-query-left-container-convert-btn-icon-mobile-left-arrow"
                    icon={faArrowLeft}
                  />
                  <span>Previous</span>
                </button>
                <button
                  className="sql-doc-bottom-button-next-mobile"
                  onClick={goToNextPage}
                >
                  <span>Next</span>
                  <FontAwesomeIcon
                    className="convert-query-left-container-convert-btn-icon-mobile-right-arrow"
                    icon={faArrowRight}
                  />
                </button>
              </div>
            </>
          )}
        </div>

        <button
          className="sql-doc-toggle-theme sql-doc-switch-icon"
          onClick={toggleTheme}
          style={{ fontSize: "30px" }}
        >
          <FontAwesomeIcon
            icon={faCircleHalfStroke}
            color={isDarkTheme ? "white" : "black"}
          />
        </button>
      </div>
    </React.Fragment>
  );
}
