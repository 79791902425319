export const SELECT_STATEMENT = 'select-statement';
export const FIND_DOCUMENTS = 'find-documents';
export const VIEW = 'View';
export const CLICK = 'Click';
export const CONVERT = 'Convert';
export const EXPLAIN = 'Explain';
export const DOCUMENTATION = 'Documentation';
export const SAVED_QUERIES = 'SavedQueries';
export const HOME = 'Home';
export const SQL = 'SQL';
export const MONGODB = 'MongoDB';
export const SOURCE_QUERY_SELECT = 'SourceQuerySelect';
export const TARGET_QUERY_SELECT = 'TargetQuerySelect';
export const FORMAT = 'Format';
export const CLEAR = 'Clear';
export const COPY = 'Copy';
export const SIDE_BAR = 'SideBar';
export const PREVIOUS = 'Previous';
export const NEXT = 'Next';
export const NEW_FOLDER = 'NewFolder';
export const RENAME_FOLDER = 'RenameFolder';
export const DELETE_FOLDER = 'DeleteFolder';
export const ADD = 'Add';
export const NEW_FOLDER_CONFIRM = 'NewFolderConfirm';
export const RENAME_FOLDER_CONFIRM = 'RenameFolderConfirm';
export const DELETE_FOLDER_CONFIRM = 'DeleteFolderConfirm';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;