const mongoDBKeywords = [
    "aggregate",
    "find",
    "insert",
    "update",
    "remove",
    "createCollection",
    "dropCollection",
    "createIndex",
    "ensureIndex",
    "dropIndex",
    "count",
    "distinct",
    "getSiblingDB",
    "getCollection",
    "getLastError",
    "getPrevError",
    "resetError",
    "getShardVersion",
    "getShardDistribution",
    "sh.status",
    "sh.enableSharding",
    "sh.shardCollection",
    "sh.splitAt",
    "sh.moveChunk",
    "sh.addTagRange",
    "sh.removeTagRange",
    "sh.addShard",
    "sh.removeShard",
    "sh.setBalancerState",
    "sh.getBalancerState",
    "sh.isBalancerRunning",
    "sh.startBalancer",
    "sh.stopBalancer",
    "sh.enableAutoSplit",
    "sh.disableAutoSplit",
    "sh.isAutoSplitEnabled",
    "sh.setShardVersion",
    "sh.setShardTag",
    "sh.addShardTag",
    "sh.removeShardTag",
    "sh.status",
    "sh.enableSharding",
    "sh.addShard",
    "sh.shardCollection",
    "sh.disableBalancing",
    "sh.enableBalancing",
    "sh.moveChunk",
    "sh.addTagRange",
    "sh.removeTagRange",
    "sh.splitAt",
    "sh.isBalancerRunning",
    "sh.startBalancer",
    "sh.stopBalancer"
  ];
  
  export default mongoDBKeywords;
  