import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./explainQueryStyles.css";
import { openaiToken } from "../../components/config.js";
import { Dropdown } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import { Tooltip as ReactToolTip } from "react-tooltip";
import { format } from "sql-formatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight,faEraser, faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";

import DispatchMessage from "../../components/dispatchMessage/DispatchMessage";
import { useDispatchMessage } from "../../components/dispatchMessage/useDispatchMessage";
import { NavbarHomeBtn } from "../../components/navbar/Navbar";
import { useCookies } from "react-cookie";
import routeData from "../../utils/RouteData";
import {
  EXPLAIN,
  VIEW,
  CLICK,
  SOURCE_QUERY_SELECT,
  FORMAT,
  CLEAR,
} from "../../data/constants";
import { eventFrequencyCall } from "../../utils/Util";

const ExplainQuery = () => {
  const [input, setInput] = useState(
    "SELECT * FROM customers WHERE id = 1234 and salary > 4500"
  );
  const [output, setOutput] = useState();
  const [selectedItemInput, setSelectedItemInput] = useState("SQL");
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [, setIsTabPortraitScreen] = useState(false);
  const { alertInfo, setDispatchMessage } = useDispatchMessage();
  const textareaRef = useRef(null);
  const [activePromises, setActivePromises] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies(["googleId"]);
  const { title, metaDescription } = routeData["/explain-query"];

  useEffect(() => {
    const mobileMq = window.matchMedia(
      "(max-width: 767px) and (orientation: portrait)"
    );
    setIsMobileScreen(mobileMq.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobileScreen(event.matches);
    };

    mobileMq.addListener(handleMediaQueryChange);

    return () => {
      mobileMq.removeListener(handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    eventFrequencyCall(cookies.googleId, EXPLAIN, VIEW, "");
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.key === "Backspace" && event.metaKey) ||
        (event.key === "Backspace" && event.ctrlKey)
      ) {
        const currentUrl = new URL(window.location.href);
        const segments = currentUrl.pathname
          .split("/")
          .filter((segment) => segment.trim() !== "");
        if (segments.length > 1) {
          segments.pop();
          currentUrl.pathname = "/" + segments.join("/");
        } else {
          currentUrl.pathname = "/";
        }

        window.location.href = currentUrl.toString();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const tabPortraitMq = window.matchMedia(
      "(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)"
    );
    setIsTabPortraitScreen(tabPortraitMq.matches);

    const handleMediaQueryChangeTabPortrait = (event) => {
      setIsTabPortraitScreen(event.matches);
    };

    tabPortraitMq.addListener(handleMediaQueryChangeTabPortrait);

    return () => {
      tabPortraitMq.removeListener(handleMediaQueryChangeTabPortrait);
    };
  }, []);

  const handleSelect = (eventKey) => {
    setSelectedItemInput(eventKey);
    if (eventKey === "SQL") {
      setInput("SELECT * FROM customers WHERE id = 1234 and salary > 4500");
      setOutput("");
    } else if (eventKey === "MongoDB") {
      setInput(
        "db.CUSTOMERS.aggregate([\n  {$match: {salary: {$gt: 4500}, id: 1234}},\n  {$grou" +
          'p: {_id: "$salary", count: {$sum: 1}}}\n])'
      );
      setOutput("");
    } else if (eventKey === "BigQuery") {
      setInput("SELECT *\nFROM customers\nWHERE id = 1234\nAND salary > 4500");
      setOutput("");
    } else if (eventKey === "Prisma") {
      setInput(
        "prisma.queryRaw(`SELECT * FROM customers WHERE id = 1234 and salary > 4500`)"
      );
      setOutput("");
    } else if (eventKey === "Oracle PL/SQL") {
      setInput("SELECT *\nFROM customers\nWHERE id = 1234\nAND salary > 4500");
      setOutput("");
    } else if (eventKey === "MS Access") {
      setInput("SELECT *\nFROM customers\nWHERE id = 1234 AND salary > 4500");
      setOutput("");
    }
    eventFrequencyCall(cookies.googleId, EXPLAIN, CLICK, SOURCE_QUERY_SELECT);
  };

  const startPromise = () => {
    setActivePromises((prev) => prev + 1);
  };

  const endPromise = () => {
    if (activePromises === 2) setActivePromises(0);
    else setActivePromises(activePromises - 1);
  };

  useEffect(() => {
    textareaRef.current.focus();
    textareaRef.current.selectionStart = textareaRef.current.value.length;
  }, []);

  const textareaOutputRef = useRef(null);

  const validateInput = () => {
    const inputValue = input.trim();
    if (inputValue === "") {
      return false;
    }
    return true;
  };

  const handleFormat = () => {
    setInput(format(input, { language: "mysql" }));
    eventFrequencyCall(cookies.googleId, EXPLAIN, CLICK, FORMAT);
  };

  const handleClear = () => {
    setInput("");
    eventFrequencyCall(cookies.googleId, EXPLAIN, CLICK, CLEAR);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateInput()) {
      return;
    }
    setOutput("");

    startPromise();
    axios
      .post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "user",
              content: `find whether this is valid ${selectedItemInput} query syntax: ${input} Just answer true or false. If query is incomplete answer false`,
            },
          ],
          temperature: 0,
          max_tokens: 1024,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${openaiToken}`,
          },
        }
      )
      .then((response) => {
        const invalidQueriesKeywords = [
          "false",
          "incomplete",
          "sorry",
          "incomplete",
          "unfortunately",
        ];
        let flag = false;
        for (let keyword of invalidQueriesKeywords) {
          if (
            response.data.choices[0].message.content
              .toLowerCase()
              .includes(keyword)
          ) {
            flag = true;
            break;
          }
        }
        if (flag === true) {
          setOutput("Provide Valid Query ");
        } else {
          setOutput(
            "Just a few seconds. Trying to provide a proper explanation"
          );
          axios
            .post(
              "https://api.openai.com/v1/chat/completions",
              {
                model: "gpt-3.5-turbo",
                messages: [
                  {
                    role: "user",
                    content: `Explain the provided ${selectedItemInput} query: ${input} and explain by dividing the query and explaining each`,
                  },
                ],
                temperature: 0,
                max_tokens: 1024,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${openaiToken}`,
                },
              }
            )
            .then((response) => {
              setOutput(response.data.choices[0].message.content);
              endPromise();
            })
            .catch((error) => {
              endPromise();
              console.error("Error in the second Axios request:", error);
              setDispatchMessage({
                errorMessage: error.toString(),
                alertType: 2,
              });
            });
        }
      })
      .catch((error) => {
        endPromise();
        console.error("Error in the first Axios request:", error);
        setDispatchMessage({
          errorMessage: error.toString(),
          alertType: 2,
        });
      });
    eventFrequencyCall(cookies.googleId, EXPLAIN, CLICK, EXPLAIN);
  };

  const convertContentToLines = (content) => {
    return content.split("").filter((line) => line.trim() !== "");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className="explain-query-bodyBgColor explain-query-mainContainer">
        <NavbarHomeBtn
          isLogoBlack={true}
          isHomeBlack={isMobileScreen ? true : false}
          source={EXPLAIN}
        ></NavbarHomeBtn>
        <DispatchMessage alertInfo={alertInfo}></DispatchMessage>
        <div className="explain-query-childContainer explain-query-querySelectorLeft">
          {/* SQL input container */}
          <div className="explain-query-containerContent">
            <div className="explain-query-header ">
              <label>{selectedItemInput}</label>
            </div>
            <div className="explain-query-textBox explain-query-leftTextBox">
              {selectedItemInput !== "MongoDB" ? (
                <div className="">
                  <button
                    type="button"
                    className="btn bg-transparent explain-query-child-container-icon-btns"
                  >
                    <FontAwesomeIcon
                      className="iconSize explain-query-child-container-format-icon"
                      icon={faMagicWandSparkles}
                      id="formatBtn"
                      aria-hidden="true"
                      onClick={handleFormat}
                    />
                  </button>
                  <ReactToolTip
                    id="tooltipStyle"
                    anchorId="formatBtn"
                    place="top"
                    content="Format"
                    variant="dark"
                  />
                </div>
              ) : null}
              <textarea
                spellCheck="false"
                ref={textareaRef}
                className="explain-query-textArea explain-query-leftTextArea"
                value={input}
                onChange={(event) => setInput(event.target.value)}
              />
              <div className="explain-query-child-container-output-btns">
                <Dropdown
                  onSelect={handleSelect}
                  className="explain-query-child-container-dropdown"
                >
                  <Dropdown.Toggle
                    variant=""
                    id="inputQueryDropdownExplainQuery"
                  >
                    {selectedItemInput}
                    <FaChevronDown />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="explain-query-ddItemStyle"
                      eventKey="SQL"
                    >
                      SQL
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="explain-query-ddItemStyle"
                      eventKey="MongoDB"
                    >
                      MongoDB
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="explain-query-ddItemStyle"
                      eventKey="BigQuery"
                    >
                      BigQuery
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="explain-query-ddItemStyle"
                      eventKey="Oracle PL/SQL"
                    >
                      Oracle PL/SQL
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="explain-query-ddItemStyle"
                      eventKey="Prisma"
                    >
                      Prisma
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="explain-query-ddItemStyle"
                      eventKey="MS Access"
                    >
                      MS Access
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="explain-query-cornerBtn">
                  <button
                    type="button"
                    className="btn bg-transparent explain-query-child-container-icon-btns"
                  >
                    <FontAwesomeIcon
                      className="explain-query-iconSize"
                      icon={faEraser}
                      id="clearBtn"
                      aria-hidden="true"
                      onClick={handleClear}
                    />
                  </button>
                  <ReactToolTip
                    id="tooltipStyle"
                    anchorId="clearBtn"
                    place="top"
                    content="Clear"
                    variant="dark"
                  />
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-dark explain-query-convertBtn "
              onClick={handleSubmit}
            >
              <span className="explain-query-convertText">
                Explain&nbsp;&nbsp;
              </span>
              <FontAwesomeIcon
                className="explain-query-convertIcon"
                icon={faArrowRight}
              />
            </button>
          </div>
        </div>

        <div className="explain-query-childContainer explain-query-querySelectorOutput">
          {/* MongoDB output container */}
          <div className="explain-query-containerContent-right">
            <div className="explain-query-header-right explain-query-fontWhite">
              <label>Explanation</label>
            </div>
            <div
              className={`explain-query-textBox-right explain-query-rightTextBox  ${
                activePromises > 0 ? " explain-query-animateBorder" : ""
              }`}
            >
              <textarea
                ref={textareaOutputRef}
                className="explain-query-textAreaRight explain-query-rightTextArea explain-query-fontWhite"
                spellCheck="false"
                readOnly
                value={output}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExplainQuery;
