import React, { useEffect, useState } from "react";
import "./dispatchMessageStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function DispatchMessage({ alertInfo }) {
  const [isVisible, setIsVisible] = useState(false);
  const alertType = alertInfo.alertType;
  useEffect(() => {
    if (alertInfo.errorMessage) {
      setIsVisible(true);

      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [alertInfo]);

  return (
    <div
      className={`dispatch-message ${isVisible ? "visible" : "hidden"} ${
        alertType === 1 ? "success" : "error"
      }`}
    >
      {alertInfo.errorMessage}
      <button
        type="button"
        className={`dispatchMessage-queries-faXMark ${
          alertType === 1 ? "success" : "error"
        }`}
        onClick={() => setIsVisible(false)}
      >
        <FontAwesomeIcon className="" icon={faXmark} />
      </button>
    </div>
  );
}

export default DispatchMessage;
