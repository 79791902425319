import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./convertQueryStyles.css";
import { Helmet } from "react-helmet";
import { openaiToken } from "../../components/config.js";
import { Dropdown } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import { Tooltip as ReactToolTip } from "react-tooltip";
import { format } from "sql-formatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCopy, faMagicWandSparkles, faEraser } from "@fortawesome/free-solid-svg-icons";
import DispatchMessage from "../../components/dispatchMessage/DispatchMessage";
import { useDispatchMessage } from "../../components/dispatchMessage/useDispatchMessage";
import { NavbarHomeBtn } from "../../components/navbar/Navbar";
import { useCookies } from "react-cookie";
import routeData from "../../utils/RouteData";
import Overlay from "../../components/overlay/Overlay";
import {
  CONVERT,
  VIEW,
  CLICK,
  SOURCE_QUERY_SELECT,
  TARGET_QUERY_SELECT,
  FORMAT,
  CLEAR,
  COPY,
} from "../../data/constants";
import { eventFrequencyCall } from "../../utils/Util";

const Convert = () => {
  const [input, setInput] = useState(
    "SELECT * FROM customers WHERE id = 1234 and salary > 4500"
  );
  const [output, setOutput] = useState();
  const [selectedItemInput, setSelectedItemInput] = useState("MySQL");

  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [issTabPortraitScreen, setIsTabPortraitScreen] = useState(false);

  const { alertInfo, setDispatchMessage } = useDispatchMessage();
  const [activePromises, setActivePromises] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies(["googleId"]);
  const { title, metaDescription } = routeData["/convert"];

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.key === "Backspace" && event.metaKey) ||
        (event.key === "Backspace" && event.ctrlKey)
      ) {
        const currentUrl = new URL(window.location.href);
        const segments = currentUrl.pathname
          .split("/")
          .filter((segment) => segment.trim() !== "");
        if (segments.length > 1) {
          segments.pop();
          currentUrl.pathname = "/" + segments.join("/");
        } else {
          currentUrl.pathname = "/";
        }

        window.location.href = currentUrl.toString();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    eventFrequencyCall(cookies.googleId, CONVERT, VIEW, "");
  }, []);

  useEffect(() => {
    const tabPortraitMq = window.matchMedia(
      "(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)"
    );
    setIsTabPortraitScreen(tabPortraitMq.matches);

    const handleMediaQueryChangeTabPortrait = (event) => {
      setIsTabPortraitScreen(event.matches);
    };

    tabPortraitMq.addListener(handleMediaQueryChangeTabPortrait);

    return () => {
      tabPortraitMq.removeListener(handleMediaQueryChangeTabPortrait);
    };
  }, []);

  useEffect(() => {
    const mobileMq = window.matchMedia(
      "(max-width: 767px) and (orientation: portrait)"
    );
    setIsMobileScreen(mobileMq.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobileScreen(event.matches);
    };

    mobileMq.addListener(handleMediaQueryChange);

    return () => {
      mobileMq.removeListener(handleMediaQueryChange);
    };
  }, []);
  const handleSelect = (eventKey) => {
    setSelectedItemInput(eventKey);
    if (eventKey === "MySQL") {
      setInput("SELECT * FROM customers WHERE id = 1234 and salary > 4500");
      setOutput("");
    } else if (eventKey === "MongoDB") {
      setInput(
        "db.CUSTOMERS.aggregate([\n  {$match: {salary: {$gt: 4500}, id: 1234}},\n  {$grou" +
          'p: {_id: "$salary", count: {$sum: 1}}}\n])'
      );
      setOutput("");
    } else if (eventKey === "BigQuery") {
      setInput("SELECT *\nFROM customers\nWHERE id = 1234\nAND salary > 4500");
      setOutput("");
    } else if (eventKey === "Prisma") {
      setInput(
        "prisma.queryRaw(`SELECT * FROM customers WHERE id = 1234 and salary > 4500`)"
      );
      setOutput("");
    } else if (eventKey === "Oracle PL/SQL") {
      setInput("SELECT *\nFROM customers\nWHERE id = 1234\nAND salary > 4500");
      setOutput("");
    } else if (eventKey === "MS Access") {
      setInput("SELECT *\nFROM customers\nWHERE id = 1234 AND salary > 4500");
      setOutput("");
    }
    eventFrequencyCall(cookies.googleId, CONVERT, CLICK, SOURCE_QUERY_SELECT);
  };

  const [selectedItemOutput, setSelectedItemOutput] = useState("MongoDB");

  const handleSelectOutput = (eventKey) => {
    setSelectedItemOutput(eventKey);
    setOutput("");
    eventFrequencyCall(cookies.googleId, CONVERT, CLICK, TARGET_QUERY_SELECT);
  };

  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.focus();
    textareaRef.current.selectionStart = textareaRef.current.value.length;
  }, []);

  const textareaOutputRef = useRef(null);

  const handleCopyToClipboard = () => {
    textareaOutputRef.current.select();
    navigator.clipboard
      .writeText(output)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });

    setTimeout(() => {
      window.getSelection().removeAllRanges();
    }, 1000);
    eventFrequencyCall(cookies.googleId, CONVERT, CLICK, COPY);
  };

  const validateInput = () => {
    const inputValue = input.trim();
    if (inputValue === "") {
      return false;
    }
    return true;
  };

  const handleFormat = () => {
    setInput(format(input, { language: "mysql" }));
    eventFrequencyCall(cookies.googleId, CONVERT, CLICK, FORMAT);
  };

  const handleClear = () => {
    setInput("");
    eventFrequencyCall(cookies.googleId, CONVERT, CLICK, CLEAR);
  };

  const startPromise = () => {
    setActivePromises((prev) => prev + 1);
  };

  const endPromise = () => {
    if (activePromises === 2) setActivePromises(0);
    else setActivePromises(0);
  };

  const handleSubmit = async ( event) => {
    event.preventDefault();
    startPromise();
    try {
      if(selectedItemInput === "MySQL") {
        const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
                // model: "ft:gpt-3.5-turbo-1106:personal::8flQplWE",
                model:"ft:gpt-3.5-turbo-1106:personal::9B40sNVq",                
                messages: [
                    {
                        role: 'system',
                        content: "You are SQL to MongoDB query converter. And convert query if its almost correct SQL Query.",
                    },
                    {
                        role: 'user',
                        content: input,
                    },
                ],
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${openaiToken}`,
                },
            }
        );
      // Extract the generated response from the API call and set it to state
      endPromise();
      setOutput(response.data.choices[0].message.content);
      }
        
    } catch (error) {
        console.error('Error:', error);
    }
};

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!validateInput()) {
  //     return;
  //   }
  //   setOutput("");
  //   startPromise();
  //   axios
  //     .post(
  //       "https://api.openai.com/v1/chat/completions",
  //       {
  //         model: "gpt-3.5-turbo",
  //         messages: [
  //           {
  //             role: "user",
  //             content: `find whether this is valid ${selectedItemInput} query syntax: ${input} Just answer true or false. If the query is incomplete or wrong, answer false. Otherwise, answer true and convert the provided ${selectedItemInput} query into the best optimal ${selectedItemOutput} Query in a formatted way without any explanation and unwanted keywords: ${input}`,
  //           },
  //         ],
  //         temperature: 0,
  //         max_tokens: 1024,
  //         top_p: 1,
  //         frequency_penalty: 0,
  //         presence_penalty: 0,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${openaiToken}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       endPromise();
  //       const messageContent = response.data.choices[0].message.content;
  //       let convertedContent = messageContent
  //         .replace("MongoDB Query:", "")
  //         .trim();
  //       convertedContent = convertedContent.replace("SQL Query:", "").trim();
  //       convertedContent = convertedContent.replace("Prism Query:", "").trim();
  //       convertedContent = convertedContent.replace("BigQuery:", "").trim();
  //       convertedContent = convertedContent.replace("MS Access:", "").trim();
  //       convertedContent = convertedContent.replace("True", "").trim();
  //       const invalidQueriesKeywords = [
  //         "False",
  //         "incomplete",
  //         "Sorry",
  //         "sorry",
  //         "incomplete",
  //         "unfortunately",
  //       ];
  //       let flag = false;
  //       for (let keyword of invalidQueriesKeywords) {
  //         if (convertedContent.startsWith(keyword)) {
  //           flag = true;
  //           break;
  //         }
  //       }
  //       if (flag === true) setOutput("Provide Valid Query to Convert");
  //       else {
  //         setOutput(convertedContent);
  //       }
  //     })
  //     .catch((error) => {
  //       endPromise();
  //       console.error("Error making the request:", error);
  //       setDispatchMessage({
  //         errorMessage: error.toString(),
  //         alertType: 2,
  //       });
  //     });
  // };

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div className="convert-query-main-container">
        <NavbarHomeBtn
          isBgDark={false}
          isHomeBlack={isMobileScreen || issTabPortraitScreen ? false : true}
          source={CONVERT}
        ></NavbarHomeBtn>
        <Overlay active={activePromises > 0} component="convertQuery" />
        <DispatchMessage alertInfo={alertInfo}></DispatchMessage>
        <div className="convert-query-child-container convert-query-left-container">
          <div className="convert-query-child-container-div">
            <div className="convert-query-child-container-div-left-header">
              <label>{selectedItemInput}</label>
            </div>
            <div className="convert-query-child-container-text-box convert-query-child-container-left-text-box">
              {selectedItemInput !== "MongoDB" ? (
                <div className="">
                  <button
                    type="button"
                    className="btn btn-transparent convert-query-child-container-icon-btns"
                  >
                    <FontAwesomeIcon
                      className="convert-query-child-container-icon convert-query-child-container-format-icon"
                      icon={faMagicWandSparkles}
                      aria-hidden="true"
                      onClick={handleFormat}
                    />
                  </button>
                  <ReactToolTip
                    id="convert-query-icons-tooltip"
                    anchorId="formatBtn"
                    place="top"
                    content="Format"
                    variant="light"
                  />
                </div>
              ) : null}
              <textarea
                spellCheck="false"
                ref={textareaRef}
                className="convert-query-child-container-text-area fontWhite convert-query-child-container-left-text-area"
                value={input}
                onChange={(event) => setInput(event.target.value)}
              />
              <div className="convert-query-child-container-output-btns">
                <Dropdown
                  // onSelect={handleSelect}
                  className="convert-query-child-container-dropdown"
                >
                  <Dropdown.Toggle
                    variant="primary"
                    id="convert-query-input-query-dropdown"
                  >
                    {selectedItemInput}
                    <FaChevronDown />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="MySQL"
                    >
                      SQL
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="MongoDB"
                    >
                      MongoDB
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="BigQuery"
                    >
                      BigQuery
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="Oracle PL/SQL"
                    >
                      Oracle PL/SQL
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="Prisma"
                    >
                      Prisma
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="MS Access"
                    >
                      MS Access
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="convert-query-child-container-corner-btn">
                  <button
                    type="button"
                    className="btn btn-dark bg-transparent convert-query-child-container-icon-btns"
                  >
                    <FontAwesomeIcon
                      className="convert-query-child-container-icon convert-query-child-container-clear-icon"
                      icon={faEraser}
                      id="clearBtn"
                      aria-hidden="true"
                      onClick={handleClear}
                    />
                  </button>
                  <ReactToolTip
                    id="convert-query-icons-tooltip"
                    anchorId="clearBtn"
                    place="top"
                    content="Clear"
                    variant="light"
                  />
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-light convert-query-left-container-convert-btn "
              onClick={handleSubmit}
            >
              <span className="convert-query-left-container-convert-btn-text">
                Convert&nbsp;&nbsp;
              </span>
              <FontAwesomeIcon
                className="convert-query-left-container-convert-btn-icon"
                icon={faArrowRight}
              />
            </button>
          </div>
        </div>

        <div className="convert-query-child-container convert-query-right-container">
          <div className="convert-query-child-container-div">
            <div className="convert-query-child-container-div-right-header">
              <label>{selectedItemOutput}</label>
            </div>
            <div
              className={`convert-query-child-container-text-box convert-query-child-container-right-text-box ${
                activePromises > 0
                  ? " "
                  : ""
              }`}
            >
              <textarea
                ref={textareaOutputRef}
                className="convert-query-child-container-text-area convert-query-child-container-right-text-area"
                spellCheck="false"
                readOnly
                value={output}
              />
              <div className="convert-query-child-container-output-btns">
                <Dropdown
                  // onSelect={handleSelectOutput}
                  className="convert-query-child-container-dropdown"
                >
                  <Dropdown.Toggle
                    variant="primary"
                    id="convert-query-output-query-dropdown"
                  >
                    {selectedItemOutput}
                    <FaChevronDown />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="convert-query-child-container-dropdown-menu">
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="MongoDB"
                    >
                      MongoDB
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="MySQL"
                    >
                      SQL
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="BigQuery"
                    >
                      BigQuery
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="Oracle PL/SQL"
                    >
                      Oracle PL/SQL
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="Prisma"
                    >
                      Prisma
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="convert-query-child-container-dropdown-items"
                      eventKey="MS Access"
                    >
                      MS Access
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="convert-query-child-container-corner-btn">
                  <button
                    type="button"
                    className="btn btn-light bg-transparent convert-query-child-right-container-icon-btns"
                  >
                    <FontAwesomeIcon
                      id="copyBtn"
                      className="convert-query-child-container-icon convert-query-child-container-clear-icon"
                      onClick={handleCopyToClipboard}
                      icon={faCopy}
                    />
                  </button>
                  <ReactToolTip
                    id="convert-query-icons-tooltip"
                    anchorId="copyBtn"
                    place="top"
                    content="Copy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Convert;
