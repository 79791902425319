import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="571094388509-qhchqaqvmikedoodp3f3jcmt0qvkccre.apps.googleusercontent.com">
    {/* <React.StrictMode> */}
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CookiesProvider>
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
);

reportWebVitals();
