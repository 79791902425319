import { useState } from "react";


export function useDispatchMessage() {
  const [alertInfo, setAlertInfo] = useState({
    errorMessage: null,
    alertType: null,
  });

  const setDispatchMessage = (newAlertInfo) => {
    setAlertInfo(newAlertInfo);
  };

  return { alertInfo, setDispatchMessage };
}
