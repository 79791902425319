import { useGoogleLogin, googleLogout } from "@react-oauth/google";
import React, { useState, useEffect } from "react";
import "./loginStyles.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import { BACKEND_URL } from "../../data/constants";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Login({ isBlackButton }) {
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      sendAuthorizationCodeToServer(codeResponse.code);
    },
    flow: "auth-code",
    onError: (error) => console.log("Login Failed:", error),
  });

  const [user, setUser] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies([
    "isLoggedIn",
    "username",
    "googleId",
  ]);
  const [googleConnectionButtonHovered, setGoogleConnectionButtonHovered] =
    useState(false);

  const sendAuthorizationCodeToServer = (authorizationCode) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: authorizationCode }),
    };

    fetch(`${BACKEND_URL}/authenticate`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setCookie("isLoggedIn", "true");
        setCookie("username", data.given_name);
        setCookie("googleId", data.id);
      })
      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    console.log(user);
  }, [user]);

  const handleMouseOver = () => {
    setGoogleConnectionButtonHovered(true);
  };

  const handleMouseOut = () => {
    setGoogleConnectionButtonHovered(false);
  };

  const logOut = () => {
    googleLogout();
    removeCookie("isLoggedIn");
    removeCookie("googleId");
    removeCookie("username");
  };

  return (
    <div className="login-btn">
      {cookies.isLoggedIn ? (
        <button
          onClick={() => logOut()}
          type="button"
          className={`btn ${
            isBlackButton ? "btn-dark " : "btn-light"
          } logout-connect-with-google`}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <FontAwesomeIcon className="home-google-icon" icon={faGoogle} />

          {googleConnectionButtonHovered === false ? (
            <span>Connected&nbsp;</span>
          ) : (
            <span>Disconnect &nbsp;</span>
          )}
          <span
            className={
              googleConnectionButtonHovered === false
                ? "online-icon-home"
                : "offline-icon-home"
            }
          >
            &nbsp;
          </span>
        </button>
      ) : (
        <button
          type="button"
          onClick={() => login()}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          className={`btn ${
            isBlackButton ? "btn-dark" : "btn-light"
          } login-connect-with-google ${
            googleConnectionButtonHovered ? "hovered" : ""
          }`}
        >
          <FontAwesomeIcon className="login-google-icon" icon={faGoogle} />

          <span>Connect With Google</span>
        </button>
      )}
    </div>
  );
}
export default Login;
