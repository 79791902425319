import React from "react";
import { Routes, Route } from "react-router-dom";
import Convert from "./pages/Convert/ConvertQuery";
import SavedQueries from "./pages/SavedQueries/SavedQueries";
import ExplainQuery from "./pages/Explain/ExplainQuery";
import Home from "./pages/Home/Home";
import Documentation from "./pages/Documentation/Documentation";
import SqlDocumentation from "./pages/Documentation/SqlDocumentation";
import { Navigate } from "react-router-dom";

import Cookies from "js-cookie";

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/convert" element={<Convert />} />
        <Route
          path="/saved-queries"
          element={
            Cookies.get("isLoggedIn") !== undefined ? (
              <SavedQueries />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route path="/explain-query" element={<ExplainQuery />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/documentation/sql/:id" element={<SqlDocumentation />} />
        <Route path="/documentation/mongo/:id" element={<SqlDocumentation />} />
        <Route path="/documentation/sql/*" element={<SqlDocumentation />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
