import React from "react";
import "./Overlay.css";

const Overlay = ({ active, component }) => {
  let className = "overlay home";

  if (active && component === "SqlDocumentation" ) {
    className = "overlay sqlDocumentation";
  }
  else
  if (active && component === "mongoDocumentation" ) {
    className = "overlayBlack mongoDocumentation";
  }
  else
  if (active && component === "savedQueries" ) {
    className = "overlay savedQueries";
  }
  else
  if (active && component === "convertQuery" ) {
    className = "overlay convertQuery";
  }

  return active ? <div className={className} ></div> : null;
};

export default Overlay;
