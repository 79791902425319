import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./savedQueriesStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAutosizeTextArea from "../../components/dynamicTextArea/UseAutosizeTextArea";
import axios from "axios";
import Overlay from "../../components/overlay/Overlay";
import lightThemeVariables from "../../data/lightThemeVariables";
import darkThemeVariables from "../../data/darkThemeVariables";
import {
  faXmark,
  faFolderOpen,
  faCircleHalfStroke,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { NavbarHomeBtn } from "../../components/navbar/Navbar";
import TextareaAutosize from "react-textarea-autosize";
import { useCookies } from "react-cookie";
import routeData from "../../utils/RouteData";
import { ADD, CLICK, DELETE_FOLDER, DELETE_FOLDER_CONFIRM, SIDE_BAR, NEW_FOLDER, NEW_FOLDER_CONFIRM, RENAME_FOLDER, RENAME_FOLDER_CONFIRM, SAVED_QUERIES, VIEW, BACKEND_URL } from "../../data/constants";
import { eventFrequencyCall } from "../../utils/Util";

export default function SavedQueries() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
  const [values, setValues] = useState([]);
  const textAreaRefs = useRef([React.createRef()]);
  const [selectedFolderId, setSelectedFolderId] = useState(-1);
  const [folderItems, setFolderItems] = useState();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [activePromises, setActivePromises] = useState(1);
  const [folderName, setFolderName] = useState(null);
  const [newFolderName, setNewFolderName] = useState("");
  const [showNewFolderModal, setShowNewFolderModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [folderToRename, setFoldertoRename] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(["googleId"]);
  const {title, metaDescription} = routeData["/saved-queries"]

  useAutosizeTextArea(textAreaRefs.current, values);

  useEffect(() => {
    eventFrequencyCall(cookies.googleId,SAVED_QUERIES,VIEW,'');
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedFolderId]);

  const startPromise = () => {
    setActivePromises((prev) => prev + 1);
  };

  const endPromise = () => {
    if (activePromises === 2) setActivePromises(0);
    else setActivePromises(activePromises - 1);
  };

  const [isDarkTheme, setIsDarkTheme] = useState(true);

  const toggleTheme = () => {
    setIsDarkTheme((prevTheme) => !prevTheme);
  };

  const themeVariables = isDarkTheme ? darkThemeVariables : lightThemeVariables;
  Object.entries(themeVariables).forEach(([key, value]) => {
    document.documentElement.style.setProperty(key, value);
  });

  const fetchData = async () => {
    startPromise();
    axios
      .post(`${BACKEND_URL}/api/savedQueries/fetchFolders`, {
        googleId: cookies.googleId,
      })
      .then((response) => {
        setTimeout(() => {
          setFolderItems(response.data);
          console.log(response.data);
          if (selectedFolderId === -1) {
            const selectedFolder = response.data[0];

            setSelectedFolder(selectedFolder);
            if (selectedFolder !== undefined) {
              const fetchQueries = selectedFolder.queryArray;

              setValues(fetchQueries);
              textAreaRefs.current = fetchQueries.map(() => React.createRef());

              if (selectedFolder !== undefined) setFolder(selectedFolder);
            }
          } else {
            const selectedFolder = response.data.find(
              (folder) => folder.folderId === selectedFolderId
            );

            if (selectedFolder !== undefined) {
              setSelectedFolder(selectedFolder);
              const fetchQueries = selectedFolder.queryArray;

              setFolder(selectedFolder);

              setValues(fetchQueries);
              textAreaRefs.current = fetchQueries.map(() => React.createRef());
            }
          }
          endPromise();
        }, 10);
      })
      .catch((error) => {
        setTimeout(() => {
          console.error("Error fetching folders:", error);
          endPromise();
        }, 10);
      });
  };

  useEffect(() => {
    if (isSaving) {
      handleSaveTextAreas();
      setIsSaving(false);
    }
  }, [isSaving]);

  const handleKeyDown = (event, index) => {
    if (
      event.key === "Backspace" &&
      values[index] === "" &&
      values.length > 1
    ) {
      const newQueries = [...values];
      const newTextAreaRefs = [...textAreaRefs.current];

      newQueries.splice(index, 1);
      newTextAreaRefs.splice(index, 1);

      setValues(newQueries);
      textAreaRefs.current = newTextAreaRefs;

      //Add if you need auto-save option on clicking backspace
      setIsSaving(true);
    }
  };

  const renameFolder = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BACKEND_URL}/api/savedQueries/renameFolder`, {
          folderId: folderToRename.folderId,
          newFolderName: newFolderName,
          googleId: cookies.googleId
        })
        .then((response) => {
          console.log(response);
          setShowRenameModal(false);
          fetchData();
          setSelectedFolderId(response.data.folder.folderId);
          eventFrequencyCall(cookies.googleId,SAVED_QUERIES,CLICK,RENAME_FOLDER_CONFIRM);
          endPromise();
        });
    });
  };

  const deleteFolder = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BACKEND_URL}/api/savedQueries/deleteFolder`, {
          folder: folderToRename,
          googleId: cookies.googleId
        })
        .then((response) => {
          console.log(response);
          setShowDeleteModal(false);
          fetchData();
          setSelectedFolderId(-1);
          eventFrequencyCall(cookies.googleId,SAVED_QUERIES,CLICK,DELETE_FOLDER_CONFIRM);
          endPromise();
        });
    });
  };

  const saveNewFolder = () => {
    startPromise();

    return new Promise((resolve, reject) => {
      axios
        .post(`${BACKEND_URL}/api/savedQueries/newFolder`, {
          folderName: newFolderName,
          googleId: cookies.googleId,
        })
        .then((response) => {
          setShowNewFolderModal(false);
          setSelectedFolderId(response.data.folder.folderId);
          eventFrequencyCall(cookies.googleId,SAVED_QUERIES,CLICK,NEW_FOLDER_CONFIRM);
          endPromise();
        });
    });
  };

  const handleAddTextArea = () => {
    const newTextAreaRef = React.createRef();
    textAreaRefs.current.push(newTextAreaRef);
    values.push("");
    setValues(values);
    handleSaveTextAreas();
    eventFrequencyCall(cookies.googleId,SAVED_QUERIES,CLICK,ADD);
  };

  const handleChange = (event, index) => {
    const newValue = event.target.value;
    const newValues = [...values];
    newValues[index] = newValue;
    setValues(newValues);
  };

  const handleSaveTextAreas = () => {
    const currentTextAreaValue = values;
    axios
      .post(`${BACKEND_URL}/api/savedQueries/updateQueries`, {
        googleId: cookies.googleId,
        folderName: selectedFolder.folderName,
        textareaInputs: currentTextAreaValue,
      })
      .then((response) => {
        console.log(response.data);
        fetchData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const setFolder = (folder) => {
    setSelectedFolderId(folder.folderId);
    setFolderName(folder.folderName);
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      if (isDropdownVisible) {
        setDropdownVisible(false);
      }
    };
    if (isDropdownVisible) {
      document.addEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isDropdownVisible]);

  const showNotebookDropdown = (e, folder) => {
    e.preventDefault();
    setDropdownPosition({ x: e.clientX, y: e.clientY });
    setDropdownVisible(true);
    setFoldertoRename(folder);
  };

  const showCreateModal = () => {
    console.log("hello");
    setNewFolderName("");
    setShowNewFolderModal(true);
    eventFrequencyCall(cookies.googleId,SAVED_QUERIES,CLICK,NEW_FOLDER);
  };

  const hideDropdown = () => {
    setDropdownVisible(false);
  };

  const handleFolderClick = (folder) => {
    setFolder(folder);
    eventFrequencyCall(cookies.googleId,SAVED_QUERIES,CLICK,SIDE_BAR);
  };

  const handleOptionClick = (option, folder) => {
    if (option === "Rename") {
      setNewFolderName(folderToRename.folderName);
      setFolder(folderToRename);
      setShowRenameModal(true);
      eventFrequencyCall(cookies.googleId,SAVED_QUERIES,CLICK,RENAME_FOLDER);
    } else if (option === "Delete") {
      setShowDeleteModal(true);
      eventFrequencyCall(cookies.googleId,SAVED_QUERIES,CLICK,DELETE_FOLDER);
    }

    hideDropdown();
  };

  const handleBlur = () => {
    console.log("Auto-saving:");
    setIsSaving(true);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.key === "Backspace" && event.metaKey) ||
        (event.key === "Backspace" && event.ctrlKey)
      ) {
        const currentUrl = new URL(window.location.href);
        const segments = currentUrl.pathname
          .split("/")
          .filter((segment) => segment.trim() !== "");
        if (segments.length > 1) {
          segments.pop();
          currentUrl.pathname = "/" + segments.join("/");
        } else {
          currentUrl.pathname = "/";
        }

        window.location.href = currentUrl.toString();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
       <div className="saved-queries-main-container saved-queries-bg-color">
      {isDarkTheme ? (
        <NavbarHomeBtn isBgLight={false} isLogoBlack={false} source={SAVED_QUERIES}></NavbarHomeBtn>
      ) : (
        <NavbarHomeBtn
          isBgLight={true}
          isLogoBlack={true}
          isHomeBlack={true}
          source={SAVED_QUERIES}
        ></NavbarHomeBtn>
      )}
      <Overlay active={activePromises > 0} component="savedQueries" />
      <button
        className="sql-doc-toggle-theme sql-doc-switch-icon"
        onClick={toggleTheme}
        style={{ fontSize: "30px" }}
      >
        <FontAwesomeIcon
          icon={faCircleHalfStroke}
          color={isDarkTheme ? "white" : "black"}
        />
      </button>
      {folderItems === undefined || folderItems.length === 0 ? (
        <div className="saved-queries-no-folders-container">
          <FontAwesomeIcon
            className="saved-queries-no-folders-icon"
            icon={faFolderOpen}
          />
          <div className="saved-queries-no-folders-child-container">
            <label className="saved-queries-no-folders-heading">
              No Folders created yet. Let's create New Folder!
            </label>
            <label className="saved-queries-no-folders-quote">
              Don't let your queries roam aimlessly. Give them a home, create a
              folder :)
            </label>
            {/* <label>So go ahead, hit that 'Create Folder' button and take the first step towards a more organized and efficient experience. Remember, every great digital journey begins with a single click.</label> */}
            <button
              type="button"
              className="btn saved-queries-create-folder-btn"
              onClick={showCreateModal}
            >
              <span>Create new folder</span>
            </button>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div className="saved-queries-side-bar">
            <button
              type="button"
              className="saved-queries-btn-add-folder"
              onClick={showCreateModal}
            >
              <span>&nbsp;&nbsp;Create New Folder</span>

              <FontAwesomeIcon icon={faPlus} className="plus-icon" />
            </button>
            <div className="saved-queries-side-bar-list">
              {folderItems &&
                folderItems.map((folder, index) => (
                  <>
                    <button
                      onContextMenu={(e) => showNotebookDropdown(e, folder)}
                      key={index}
                      type="button"
                      className={`saved-queries-folder ${
                        folder.folderId === selectedFolderId
                          ? "saved-queries-folder-selected-btn"
                          : ""
                      }`}
                      onClick={() => handleFolderClick(folder)}
                    >
                      <span>
                        {folder.folderName} ({folder.queryArray.length})
                      </span>
                    </button>
                    {isDropdownVisible && (
                      <div
                        className="saved-queries-dropdown-right-click"
                        style={{
                          top: dropdownPosition.y + 2,
                          left: dropdownPosition.x,
                        }}
                      >
                        <ul>
                          <li
                            onClick={() => handleOptionClick("Rename", folder)}
                          >
                            Rename Folder
                          </li>
                          <li
                            onClick={() => handleOptionClick("Delete", folder)}
                          >
                            Delete Folder
                          </li>
                        </ul>
                      </div>
                    )}
                  </>
                ))}
            </div>
          </div>
          <div className="saved-queries-content querySelector">
            <label className="saved-queries-heading">{folderName}</label>

            {values.map((query, index) => (
              <div key={index}>
                <TextareaAutosize
                  onBlur={handleBlur}
                  spellCheck="false"
                  onChange={(event) => handleChange(event, index)}
                  onKeyDown={(event) => handleKeyDown(event, index)}
                  placeholder={index === 0 ? "Enter your Query" : null}
                  className="saved-queries-textarea"
                  ref={textAreaRefs.current[index]}
                  maxRows={30}
                  value={values[index]}
                />
              </div>
            ))}
            <div className="saved-queries-btns">
              <button
                type="button"
                onClick={handleAddTextArea}
                className="saved-queries-add-btn"
              >
                <FontAwesomeIcon className="" icon={faAdd} />
              </button>
              {/* <button
                type="button"
                className="btn btn-light saved-queries-saveBtn"
                onClick={handleSaveTextAreas}
              >
                <span>Save</span>
              </button> */}
            </div>
          </div>
        </>
      )}
      {showNewFolderModal && (
        <div className="saved-queries-popup">
          <div className="saved-queries-popup-content">
            <label className="saved-queries-popup-heading">
              Create new folder
            </label>
            <label className="saved-queries-popup-info">
              Create your folder and arrange your common queries.
            </label>

            <input
              className="saved-queries-popup-input"
              placeholder="Folder name"
              value={newFolderName}
              spellCheck={false}
              onChange={(e) => setNewFolderName(e.target.value)}
            />

            <button
              className="btn btn-dark saved-queries-save-btn"
              onClick={saveNewFolder}
            >
              Save
            </button>
            <button
              type="button"
              className="saved-queries-faXMark"
              onClick={() => setShowNewFolderModal(false)}
            >
              <FontAwesomeIcon className="" icon={faXmark} />
            </button>
          </div>
        </div>
      )}
      {showRenameModal && (
        <div className="saved-queries-popup">
          <div className="saved-queries-popup-content">
            <label className="saved-queries-popup-heading">Rename Folder</label>
            <input
              className="saved-queries-popup-input"
              placeholder="Folder name"
              value={newFolderName}
              spellCheck={false}
              onChange={(e) => setNewFolderName(e.target.value)}
            />

            <button
              className="btn btn-dark saved-queries-save-btn"
              onClick={renameFolder}
            >
              Save
            </button>
            <button
              type="button"
              className="saved-queries-faXMark"
              onClick={() => setShowRenameModal(false)}
            >
              <FontAwesomeIcon className="" icon={faXmark} />
            </button>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <div className="saved-queries-popup">
          <div className="saved-queries-popup-content">
            <label className="saved-queries-popup-heading">
              Delete the folder
            </label>
            <label className="saved-queries-popup-info">
              Are you sure you want to delete the folder{" "}
              <b>{folderToRename.folderName}</b>?
            </label>

            <button
              className="btn btn-dark saved-queries-save-btn"
              onClick={deleteFolder}
            >
              Delete
            </button>
            <button
              type="button"
              className="saved-queries-faXMark"
              onClick={() => setShowDeleteModal(false)}
            >
              <FontAwesomeIcon className="" icon={faXmark} />
            </button>
          </div>
        </div>
      )}
    </div>
    </React.Fragment>
   
  );
}
