import axios from "axios";
import {BACKEND_URL} from "../data/constants"

export function eventFrequencyCall (googleId, sourcePage, action, targetElement) {
    console.log("event call")
    console.log(sourcePage+' '+action+' '+targetElement);
    axios
    .post(`${BACKEND_URL}/api/eventFrequency`, {
        googleId: googleId || '',
        sourcePage: sourcePage,
        action: action,
        targetElement: targetElement
    })
    .then((response) => {
        console.log(response);
    });
  };